import React, { Component } from 'react';
import './StreamComponent.css';
import OvVideoComponent from './OvVideo';

export default class StreamComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { nickname: this.props.user.getNickname(), mutedSound: false, isFormValid: true };
        this.handleChange = this.handleChange.bind(this);
        this.toggleSound = this.toggleSound.bind(this);
    }

    handleChange(event) {
        this.setState({ nickname: event.target.value });
        event.preventDefault();
    }

    toggleSound() {
        this.setState({ mutedSound: !this.state.mutedSound });
    }

    render() {
        return (
            <div className="videochat-layout__video">
                {this.props.user !== undefined && this.props.user.getStreamManager() !== undefined ? (
                    <div className="streamComponent">
                        <OvVideoComponent user={this.props.user} mutedSound={this.state.mutedSound} />
                    </div>
                ) : null}
            </div>
        );
    }
}
