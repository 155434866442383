import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ModalContainerNew from "../../../Components/Modal/ModalContainerNew";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { PayStatus } from "./PayStatus";

dayjs.extend(duration);

const PayPsbModal = ({ paymentData, onClose, onPaidClose }) => {
  const [paymentModal, setPaymentModal] = useState(true);
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);

  const [timeLeft, setTimeLeft] = useState(dayjs.duration(10 * 60 * 1000)); // 10 минут

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        const newTime = prev.subtract(1, "second");
        if (newTime.asSeconds() <= 0) {
          clearInterval(interval);
          return dayjs.duration(0);
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.action === "closePaymentModal") {
        setPaymentModal(false);
        setPaymentStatusModal(true);
      }
    };

    // Добавляем слушателя события message
    window.addEventListener("message", handleMessage);

    // Возвращаем функцию очистки, чтобы удалить слушателя при размонтировании компонента
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const onClosePaidModal = (paidStatus) => {
    if (onClose) {
      onClose();
    }
    if (paidStatus === "succeeded") {
      setPaymentStatusModal(false);
      if (!paymentData.payment_link) {
        window.location.href = "/my-consultation";
      } else {
        if (onPaidClose) {
          onPaidClose(paidStatus);
        }
      }
    }
  };

  return (
    <>
      <ModalContainerNew
        openModal={paymentModal}
        setOpenModal={(status) => {
          setPaymentModal(status);
        }}
        onClose={() => {
          if (onClose) {
            onClose();
          }
        }}
      >
        <div style={{ height: "100%", textAlign: "center" }}>
          <div style={{ marginBottom: "12px" }}>
            Завершите платёж в течение{" "}
            <b>{`${timeLeft.minutes()}:${String(timeLeft.seconds()).padStart(
              2,
              "0"
            )}`}</b>
          </div>
          <iframe
            style={{ height: "70vh", width: "100%" }}
            src={paymentData.url}
            frameBorder={"0"}
            title="ПСБ"
          ></iframe>
        </div>
      </ModalContainerNew>
      {paymentStatusModal ? (
        <PayStatus
          openModal={paymentStatusModal}
          setOpenModal={setPaymentStatusModal}
          paymentData={paymentData}
          onClose={(paidStatus) => {
            onClosePaidModal(paidStatus);
          }}
        />
      ) : null}
    </>
  );
};
export default PayPsbModal;
