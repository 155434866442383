import React, { useState } from "react";
import s from "./PaymentLink.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getConfigHeaderAction } from "../../../base/Reducers/configReducer";
import { useParams } from "react-router-dom";
import axiosRequest from "../../../base/axiosRequest";
import { PayButton } from "../../../Components/PayButton/PayButton";
const PaymentLink = () => {
  let dispatch = useDispatch(),
    params = useParams();

  const config = useSelector((state) => state.config.config);
  useEffect(() => {
    dispatch(getConfigHeaderAction("Оплата"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [paymentData, setPaymentData] = useState(false);
  const [title, setTitle] = useState("Оплата");
  const [email, setEmail] = useState("");
  const [successPaid, setSuccessPaid] = useState(false);

  const getPayData = async (code) => {
    const response = await axiosRequest({
      method: "get",
      endpoint: `payment-link/${code}`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((_error) => {
        setTitle("Счет уже оплачен или не существует");
        return false;
      });

    if (response.status && response.data) {
      setPaymentData(response.data);
    } else {
      setTitle("Счет уже оплачен или не существует");
    }
  };

  useEffect(() => {
    getPayData(params.id);
  }, [params.id]);

  const emailIsValid = (value) => {
    return /^[a-zA-Z0-9|\-|_|.]+@[a-zA-Z0-9|\-|_|.]+$/.test(value);
  };

  const handleSuccessPaid = () => {
    setSuccessPaid(true);
  };

  return (
    <div className={s.PrivatePoliceFull + " " + s.Container}>
      <div>
        <div style={{ color: config?.config.colors.color2 }}>
          <h1 className={s.Font_size40}>{title}</h1>
          {paymentData ? (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              {!successPaid ? (
                <>
                  <div>Описание: {paymentData.description}</div>
                  <div style={{ fontWeight: 800 }}>
                    Сумма: {paymentData.amount}
                  </div>
                  {!paymentData.is_set_email ? (
                    <>
                      <label>
                        Укажите ваш E-Mail адрес
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        value={email}
                        style={{ padding: "6px", borderRadius: "8px" }}
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                      />
                    </>
                  ) : null}
                  <PayButton
                    link_code={params.id}
                    email={email}
                    disabled={!paymentData.is_set_email && !emailIsValid(email)}
                    onSuccesPaid={handleSuccessPaid}
                  />
                </>
              ) : (
                <div>Успешно оплачено</div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default PaymentLink;
