import React, { useEffect, useState, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./SliderDots.css";
import { Pagination } from "swiper";
import { getSlider } from "../../../base/asyncActions/getMainPageInfo";
import { useSelector, useDispatch } from "react-redux";

const SliderDots = ({ children, onClick, ...props }) => {
  const [Slider, setSlider] = useState([]),
    dispatch = useDispatch(),
    SliderInfo = async () => {
      const response = await dispatch(getSlider());
      if (response && response.status) {
        setSlider(response.data.items);
      }
    };
  const handleClick = useCallback((e) => {
    e.preventDefault();
  }, []);
  const config = useSelector(
    (state) => state.config.config
  );
  useEffect(() => {
    SliderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section style={{ marginBottom: "40px" }}>
      {!Slider ? (
        <></>
      ) : (
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="mySwiper"
          loop={true}
        >
          {Slider.map((el, key) => (
            <SwiperSlide
              key={key}
              style={{
                backgroundColor: el.backgroud_color,
                backgroundImage: "url(" + el.backgroud_image + ")",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <a
                target={el.url ? "blank" : ""}
                href={el.url ? el.url : null}
                onClick={el.url ? null : handleClick}
              >
                <img alt="" src={config.platform === 'desktop' ? el.image : el.mobile_image } />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </section>
  );
};

export default SliderDots;
