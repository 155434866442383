import React, { useEffect } from "react";
import s from "./Messages.module.css";
import Button from "../../../../Components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  getShortInfo,
} from "../../../../base/asyncActions/getMainPageInfo";
import { getConfigHeaderAction } from "../../../../base/Reducers/configReducer";
import {
  axiosProfileDeleteNot,
  axiosProfileDeleteNotAll,
} from "../../../../base/asyncActions/Profile";
const Messages = () => {
  let dispatch = useDispatch();
  const NewArray = useSelector((state) => state.main.notification);
  let page = useSelector((state) => state.main.current_page);
  let totalPage = useSelector((state) => state.main.total_page);
  const config = useSelector((state) => state.config.config);
  const asyncNotification = async () => {
    dispatch(getNotification(page));
  };
  const asyncNotificationDelete = async (notofication_id) => {
    const response = await dispatch(axiosProfileDeleteNot(notofication_id));
    if (response.status) dispatch(getNotification(page));
  };
  const asyncNotificationDeleteAll = async () => {
    const response = await dispatch(axiosProfileDeleteNotAll());
    if (response.status) dispatch(getNotification(1));
  };
  const asyncNotificationShow = async () => {
    dispatch(getNotification(++page));
  };
  useEffect(() => {
    let titleText = "Уведомления";
    asyncNotification();
    dispatch(getConfigHeaderAction(titleText));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    dispatch(getShortInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NewArray]);
  return (
    <div className={s.Messages_full}>
      {NewArray[0] ? (
        NewArray.map((item, key) => (
          <div className={s.Messages_block + " black_config"} key={key}>
            <div
              className={s.Messages_close}
              onClick={(e) => asyncNotificationDelete(item.notofication_id)}
            >
              &times;
            </div>
            <div className={s.Messages_text}>
              <h1 className={s.Font_size24 + " title_config"}>{item.title}</h1>
              <p className={s.Font_size14}>{item.description}</p>
              <p className={s.Font_size14 + " gray_config"}>
                {new Date(item.datetime).toLocaleDateString("ru", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </p>
            </div>
            <div className={s.Messages_line}></div>
          </div>
        ))
      ) : (
        <h4 className={s.text_notice + " gray_config"}>
          Новых уведомлений нет
        </h4>
      )}
      <div className={s.Message_button}>
        {page < totalPage ? (
          <div
            className={s.Message_button_margin}
            onClick={asyncNotificationShow}
          >
            <Button
              className={s.Show_more + " " + s.Font_size14}
              type={"submit"}
              class={"btn white"}
              text={"Показать ещё"}
            />
          </div>
        ) : (
          ""
        )}
        <div onClick={asyncNotificationDeleteAll}>
          <Button
            className={s.Show_more + " " + s.Font_size14}
            type={"submit"}
            class={"btn orange"}
            text={"Очистить"}
          />
        </div>
      </div>
    </div>
  );
};
export default Messages;
