import React from "react";
import s from "./Med_Cart.module.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axiosProfileMedCart } from "../../../../base/asyncActions/Profile";
import Loader from "../../../../Components/Loading/Loader";
import { Link } from "react-router-dom";
import { getConfigHeaderAction } from "../../../../base/Reducers/configReducer";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const Med_Cart = () => {
  let dispatch = useDispatch();
  let medCart = useSelector((state) => state.profile.med_cart);
  const config = useSelector((state) => state.config.config);
  useEffect(() => {
    let titleText = "Медицинская карта";
    dispatch(axiosProfileMedCart());
    dispatch(getConfigHeaderAction(titleText));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);
  let keyNum = 0;
  if (medCart.fio) {
    let history = medCart.disease_history.map((el) => (
      <Tr key={++keyNum} className={s.Font_size16}>
        <Td data-label="ACCOUNT">{el.date}</Td>
        <Td data-label="DUE DATE">{el.specialty}</Td>
        <Td data-label="AMOUNT">{el.doctor}</Td>
        <Td data-label="PERIOD">{el.diagnosis}</Td>
        <Td className={s.MedCenter}>
          {el.icd_code}
          &nbsp;
          <a
            href={el.file ? el.file : ""}
            target="_blank"
            rel="noreferrer"
            download
          >
            Скачать
          </a>
        </Td>
      </Tr>
    ));
    let medical = medCart.medical_commissions.map((el) => (
      <Tr key={++keyNum} className={s.Font_size16}>
        <Td data-label="ACCOUNT">{el.date}</Td>
        <Td data-label="DUE DATE">
          {el.doctors.map((el) => (
            <span key={++keyNum} className={s.sostav}>
              <p>{el.specialty + "-"}</p>
              <p>{el.fio}</p>
            </span>
          ))}
        </Td>
        <Td data-label="AMOUNT" className={s.MedCenter}>
          {el.diagnosis}
          &nbsp;
          <Link
            to={el.file ? el.file : ""}
            className={"blue_config"}
            target="_blank"
            download
          >
            Скачать
          </Link>
        </Td>
      </Tr>
    ));
    let operations = medCart.operations.map((el) => (
      <Tr key={++keyNum} className={s.Font_size16}>
        <Td data-label="ACCOUNT">{el.date}</Td>
        <Td data-label="DUE DATE">{el.operation}</Td>
      </Tr>
    ));
    let vaccinations = medCart.vaccinations.map((el) => (
      <Tr key={++keyNum} className={s.Font_size16}>
        <Td data-label="ACCOUNT">{el.date}</Td>
        <Td data-label="DUE DATE">{el.vaccination}</Td>
      </Tr>
    ));
    return medCart.vaccinations ? (
      <div className={s.Med_Cart + " black_config"}>
        <div className={s.Med_Cart_Title + " title_config"}>
          <h1>Медицинская карта</h1>
        </div>
        <div className={s.Med_Cart_info_full}>
          <div className={s.Med_Cart_info}>
            <ul>
              <li>
                <p className={s.Font_size16_margin}>{medCart.fio}</p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>
                  Адрес регистрации: {medCart.address}
                </p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>СНИЛС: {medCart.snils}</p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>
                  Наименование страховой компании: {medCart.insurance_company}
                </p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>
                  Серия номер полиса: {medCart.policy_number}
                </p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>Экстренный анамнез</p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>
                  Пол: {medCart.gender ? "Женский" : "Мужской"}
                </p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>
                  Дата рождения: {medCart.birthday}
                </p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>
                  Группа здоровья: {medCart.health_group}
                </p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>
                  Группа крови: {medCart.blood_type}
                </p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>Rh-фактор: {medCart.rh_factor}</p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>
                  Алергические реакции: {medCart.allergic_reactions.join("/")}
                </p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>
                  Хронические заболевания: {medCart.chronic_diseases.join("/")}
                </p>
              </li>
              <li>
                <p className={s.Font_size16_margin}>История заболеваний</p>
              </li>
            </ul>
          </div>
          {/* TODO prerelease fix */}
          {/* <a href={medCart.file} rel="noreferrer" target="_blank" download>
            <div className={s.Download_file + " black_config"}>
              <div className={s.Download_img}>
                <img
                  alt=""
                  src="https://api.telemed.dev-h.ru/images/ui/download_guy.svg"
                />
              </div>
              <div className={s.Download_text}>
                <p className={s.Font_size14 + " blue_config"}>
                  Скачать заключение врача
                </p>
              </div>
            </div>
          </a> */}
        </div>
        <div className={s.Med_Cart_table}>
          <Table>
            <Thead>
              <Tr className={s.Font_size16}>
                <Th scope="col">Дата</Th>
                <Th scope="col">Специальность</Th>
                <Th scope="col">Врач</Th>
                <Th scope="col">Диагноз</Th>
                <Th scope="col">Код МКБ</Th>
              </Tr>
            </Thead>
            <Tbody>{history}</Tbody>
          </Table>
        </div>
        <ul>
          <li className={s.Med_Cart_Text}><p className={s.Font_size16_margin}>Врачебные комиссии, консилиумы</p></li>
        </ul>
        <div className={s.Med_Cart_table + " " + s.Font_size16}>
          <Table>
            <Thead>
              <Tr className={s.Font_size16}>
                <Th scope="col">Дата</Th>
                <Th scope="col">Состав</Th>
                <Th scope="col">Решение</Th>
              </Tr>
            </Thead>
            <Tbody>{medical}</Tbody>
          </Table>
        </div>
        <ul>
          <li className={s.Med_Cart_Text + " " + s.Font_size16}>
            <p className={s.Font_size16_margin}>Травмы/Операции</p>
          </li>
        </ul>
        <div className={s.Med_Cart_table}>
          <Table>
            <Thead>
              <Tr className={s.Font_size16}>
                <Th scope="col">Дата</Th>
                <Th scope="col">Травма/операция</Th>
              </Tr>
            </Thead>

            <Tbody>{operations}</Tbody>
          </Table>
        </div>
        <ul>
          <li className={s.Med_Cart_Text + " " + s.Font_size16}>
            <p className={s.Font_size16_margin}>Прививки и вакцины</p>
          </li>
        </ul>
        <div className={s.Med_Cart_table}>
          <Table>
            <Thead>
              <Tr className={s.Font_size16}>
                <Th scope="col">Год</Th>
                <Th scope="col">Прививка</Th>
              </Tr>
            </Thead>

            <Tbody>{vaccinations}</Tbody>
          </Table>
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
};
export default Med_Cart;
