import React from "react";
import s from "./Result.module.css";
import download from "../../../../img/download_file.png";
import "./drop.css";
import axios from "axios";
import { defaultUrl } from "../../../../base/configUrl";

const FileItem = ({ el, consultation }) => {
  const formatBytes = (bytes) => {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1048576) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes < 1073741824) {
      return (bytes / 1048576).toFixed(2) + " MB";
    } else {
      return (bytes / 1073741824).toFixed(2) + " GB";
    }
  };

  const downloadFile = (uuid) => {
    const token = localStorage.getItem("token");
    if (token)
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios
      .get(
        `${defaultUrl}consultation/${consultation.consultation_id}/file/${uuid}`
      )
      .then(
        (response) => {
          if (response.data.status && response.data.file) {
            window.open(response.data.file, "_blank").focus();
          } else {
            alert("Ошибка загрузки файла");
          }
        },
        (error) => {
          alert("Ошибка загрузки файла");
        }
      );
  };

  return (
    <div className={s.Download_File_PDF} style={{ width: "100%" }}>
      <div className={s.Download_File_block}>
        <div className={s.Download_File_left}>
          <div className={s.Download_File_left_text}>
            <div className={s.Download_file_flex}>
              <svg
                className="blue_svg"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 32 32"
              >
                <path d="M27.5 31h-21a.5.5 0 010-1H27V3.5a.5.5 0 011 0v27a.5.5 0 01-.5.5z"></path>
                <path d="M25.5 29h-21a.5.5 0 01-.5-.5v-21a.5.5 0 011 0V28h20V2H12.5a.5.5 0 010-1h13a.5.5 0 01.5.5v27a.5.5 0 01-.5.5z"></path>
                <path d="M10.5 8H4.521a.499.499 0 01-.354-.853l5.979-6A.5.5 0 0111 1.5v6a.5.5 0 01-.5.5zM5.726 7H10V2.71L5.726 7zM7.5 15h8a.5.5 0 000-1h-8a.5.5 0 000 1zm0 2h5a.5.5 0 000-1h-5a.5.5 0 000 1zm7 0h8a.5.5 0 000-1h-8a.5.5 0 000 1zm-7 4h7a.5.5 0 000-1h-7a.5.5 0 000 1zm0-2h10a.5.5 0 000-1h-10a.5.5 0 000 1zm12 0h3a.5.5 0 000-1h-3a.5.5 0 000 1zm-2-4h5a.5.5 0 000-1h-5a.5.5 0 000 1zm-8-4h13a.5.5 0 000-1h-13a.5.5 0 000 1zm-2 2h15a.5.5 0 000-1h-15a.5.5 0 000 1z"></path>
              </svg>
              <b className={s.Font_size16}>{el.name}</b>
            </div>
            <p className={s.Font_size14 + " " + s.gray + " gray_config"}>
              файл
            </p>
          </div>
        </div>
        <div className={s.Download_File_right}>
          <div
            onClick={() => {
              downloadFile(el.id);
            }}
            style={{ cursor: "pointer" }}
          >
            <div className={s.Download_File_right_text + " black_config"}>
              <img alt="" src={download} />
              <p className={s.Font_size14}>{formatBytes(el.size)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileItem;
