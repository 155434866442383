import axios from "axios";
import { defaultUrl } from "../configUrl";

export const setUserDevicePushStatus = async (deviceId, status) => {
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  const response = await axios.post(
    `${defaultUrl}user/device/${deviceId}/notification`,
    {
      push_enabled: status,
    }
  );
  return response.data?.status ?? false;
};
