import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrow from "../../../img/arrow.png";
import { useParams } from "react-router";
import "../Select.css";
import { DoctorMyName } from "../../../base/Reducers/doctorReducer";
import {
  getConfigHeaderAction,
  getConfigTitleAction,
} from "../../../base/Reducers/configReducer";
const SelectLogin = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sort = useSelector((state) => state.doctorSpec.sort);
  const config = useSelector((state) => state.config.config);
  const [isShown, setIsShown] = useState(false);
  const [Showtext, setShowText] = useState("...");
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  useEffect(() => {
    if (props.selectType === "sort" && Showtext === "...")
      setShowText("По популярности");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.array[0] && Showtext === "...") {
      if (props.selectType === "specialization") {
        if (params.spec_code === undefined) {
          setShowText("Все");
          dispatch(DoctorMyName("Все"));
          dispatch(getConfigHeaderAction("Все"));
          dispatch(getConfigTitleAction("Все"));
        } else {
          let filt = props.array.filter((el) => {
            return el.code === params.spec_code;
          });
          let subSpec = typeof filt[0] !== "undefined" ? filt[0].title : "Все";
          setShowText(subSpec);
          dispatch(getConfigTitleAction(subSpec));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.array]);
  const handleClickChange = (changeEvent) => {
    setShowText(changeEvent.target.title);
    if (props.selectType === "specialization") {
      const value = changeEvent.target.value
        ? changeEvent.target.value
        : undefined;
      dispatch(props.func(1, params.code, sort, value));
      const newUrl = !value
        ? "/doctors/" + params.code
        : "/doctors/" + params.code + "/" + value;
      navigate(newUrl);
      dispatch(DoctorMyName(changeEvent.target.title));
      dispatch(getConfigHeaderAction(changeEvent.target.title));
      dispatch(getConfigTitleAction(changeEvent.target.title));
    }

    if (props.selectType === "sort")
      dispatch(
        props.func(1, params.code, changeEvent.target.value, params.spec_code)
      );
  };
  let arrayItems = props.array?.map((el, key) => (
    <label htmlFor={el.branch_id ? el.branch_id : el.code} key={key}>
      <input
        type="radio"
        name="main-categories"
        title={el.title}
        id={el.branch_id || el.branch_id === 0 ? el.branch_id : el.code}
        value={el.branch_id || el.branch_id === 0 ? el.branch_id : el.code}
        onChange={handleClickChange}
      />
      <span className="Select_content_text">{el.title}</span>
    </label>
  ));
  return (
    <div id="Select-hide" onClick={handleClick}>
      {isShown ? <div className="background"></div> : ""}
      <div className="Select_content">
        <p
          style={{
            border: ` 1px solid ${config?.config.colors.color6}`,
            color: config?.config.colors.color2,
          }}
        >
          {Showtext}
        </p>
        <img alt="" src={arrow} className={isShown ? "Rotate_img" : ""} />
      </div>
      {isShown && <div id="Select-menu">{arrayItems}</div>}
    </div>
  );
};
export default SelectLogin;
