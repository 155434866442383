import React from "react";
import s from "./Razdeli.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axiosBranch } from "../../../base/asyncActions/getDoctors";
import { NavLink } from "react-router-dom";
import { getConfigHeaderAction } from "../../../base/Reducers/configReducer";
import Chat from "../../../Components/Chat/Chat";
import "../../../config.css";
import Skeleton from "react-loading-skeleton";
const Razdeli = (mainPage = false) => {
  const dispatch = useDispatch();
  let Branch = useSelector((state) => state.doctorSpec.branch_array);
  let [statusDoc, setStatus] = useState(false);
  const [BranchSort, setShowBranchSort] = useState(Branch);
  useEffect(() => {
    async function fetchMyAPI() {
      let statusDoctor = await dispatch(axiosBranch());
      setStatus(statusDoctor.status);
      if (!mainPage) {
        window.scrollTo(0, 0);
        dispatch(getConfigHeaderAction("Разделы медицины"));
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setShowBranchSort(Branch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Branch]);
  const [Showtext, setShowText] = useState("");
  function handleChange(e) {
    setShowText(e.target.value);
    let BranchSort1 = Branch.filter(
      (el) => el.keywords.indexOf(e.target.value.toLowerCase()) !== -1
    );
    setShowBranchSort(BranchSort1);
  }
  let Branch_list = BranchSort.map((el) => (
    <NavLink to={"/doctors/" + el.code} key={el.branch_id}>
      <div className={s.card_item + " title_config opacityBlue"}>
        <img alt="" src={el.image} />
        <div className={s.card_text_wrapper}>
          <div className={s.card_title}>{el.title}</div>
          <div className={s.card_subtitle}>{el.description}</div>
        </div>
      </div>
    </NavLink>
  ));
  let sceletonMap = [];
  for (var i = 0; i < 8; i++) {
    sceletonMap.push(i);
  }
  let loader = sceletonMap.map((sceletionId) => {
    return (
      <Skeleton
        key={sceletionId}
        className={s.card_item + " title_config opacityBlue"}
      />
    );
  });
  return (
    <section className={s.medicine + " " + s.container}>
      {!mainPage ? (
        <h2
          className={s.medicine_title + " " + s.Font_size40 + " title_config"}
        >
          Разделы медицины
        </h2>
      ) : null}
      <div
        className={s.medicine_input}
        style={
          mainPage
            ? {
                display: "flex",
                justifyContent: "center",
                width: "100%",
                maxWidth: "100%",
              }
            : {}
        }
      >
        <input
          type="text"
          placeholder="Поиск по разделам"
          className={s.Register_form}
          value={Showtext}
          onChange={handleChange}
        />
      </div>
      <div className={s.medicine_cards}>
        {!statusDoc ? loader : Branch_list}
      </div>
      <Chat />
    </section>
  );
};
export default Razdeli;
