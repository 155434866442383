import React, { useEffect, useRef, useState } from "react";
import cupIcon from "../../../../../../img/cup.png";
import messageIcon from "../../../../../../img/chat_message.png";
import "./ChatComponent.css";
import ModalContainerNew from "../../../../../../Components/Modal/ModalContainerNew";
import Result from "../../../../My_Profile/Result/Result";
import FileSelect from "../../../../../../Components/FileSelect/fileSelect";
import { defaultUrl } from "../../../../../../base/configUrl";
import axios from "axios";

const ChatSendComponent = ({ user, consultation }) => {
  const [message, setMessage] = useState("");
  const [researchStatusModal, setResearchStatusModal] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [filesCounter, setFilesCounter] = useState(0);
  const [uploadFileInfo, setUploadFileInfo] = useState({});
  const fileRef = useRef();

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handlePressKey = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (user && message) {
      let newMessage = message.replace(/ +(?= )/g, "");
      if (newMessage !== "" && newMessage !== " ") {
        const data = {
          type: "message",
          time: Date.now(),
          message: newMessage,
          nickname: user.getNickname(),
          photo: user.getPhoto(),
          streamId: user.getStreamManager().stream.streamId,
        };
        user.getStreamManager().stream.session.signal({
          data: JSON.stringify(data),
          type: "chat",
        });
      }
    }
    setMessage("");
  };

  const addResearch = () => {
    setResearchStatusModal(true);
  };

  const onSelectResearch = (research) => {
    setResearchStatusModal(false);
    const data = {
      type: "research",
      time: Date.now(),
      nickname: user.getNickname(),
      photo: user.getPhoto(),
      streamId: user.getStreamManager().stream.streamId,
      research: research,
    };
    user.getStreamManager().stream.session.signal({
      data: JSON.stringify(data),
      type: "chat",
    });
  };

  const onUploadFile = (file) => {
    const data = {
      type: "file",
      time: Date.now(),
      nickname: user.getNickname(),
      photo: user.getPhoto(),
      streamId: user.getStreamManager().stream.streamId,
      file: file,
    };
    user.getStreamManager().stream.session.signal({
      data: JSON.stringify(data),
      type: "chat",
    });
  };

  const [files, setFiles] = useState([]);
  const handleChangeFiles = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };
  const unsetFileById = (fileId) => {
    setFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile.id !== fileId)
    );
  };

  const sendFile = () => {
    fileRef.current.openFileChooser();
  };

  useEffect(() => {
    if (typeof files[0] !== "undefined") {
      uploadFile(files[0]);
    }
    if (files.length <= 0) {
      setFilesCounter(0);
    }
    if (files.length > filesCounter) {
      setFilesCounter(files.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const uploadFile = (file) => {
    setUploadFileInfo(file);
    const formData = new FormData();
    formData.append(
      "file",
      new Blob([file], { type: file.type }),
      file.name || "file"
    );

    axios
      .post(
        `${defaultUrl}consultation/${consultation.consultation_id}/file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percent = (progressEvent.loaded / file.size) * 100;
            setUploadProgress(parseInt(percent));
          },
        }
      )
      .then((response) => {
        unsetFileById(file.id);
        setUploadProgress(0);
        if (response.data.data.file) {
          onUploadFile(response.data.data.file);
        }
      })
      .catch((err) => {
        console.log(`Error uploading files: ${err.message}`);
      });
  };

  return (
    <FileSelect
      onChange={handleChangeFiles}
      multiple={true}
      clickable={false}
      ref={fileRef}
    >
      <div className="ChatInputContainer">
        {files.length > 0 ? (
          <div className="uploadNotice">
            <div className="uploadInfo">
              <div className="uploadInfoName">
                {filesCounter - files.length + 1} из {filesCounter} -{" "}
                {uploadFileInfo.name}
              </div>
              <div className="uploadInfoSize">
                ({uploadFileInfo.sizeReadable})
              </div>
            </div>
            <div className="uploadProgressBar">
              <div
                className="uploadProgressPercent blue"
                style={{ width: uploadProgress + "%" }}
              />
            </div>
          </div>
        ) : null}
        <div className="MessageText">
          <div className="SendFileChat">
            <div className="SendFileVariants">
              <div
                className="SendFileVariant"
                onClick={() => {
                  sendFile();
                }}
              >
                Отправить файл
              </div>
              <div className="SendFileVariant" onClick={addResearch}>
                Выбрать исследование
              </div>
            </div>
            <img src={cupIcon} alt="" />
          </div>
          <div className="MessageImg">
            <input
              type="text"
              placeholder="Введите текст сообщения..."
              value={message}
              onChange={handleChange}
              onKeyDown={handlePressKey}
            />
            <img src={messageIcon} alt="" onClick={sendMessage} />
          </div>
          <ModalContainerNew
            openModal={researchStatusModal}
            setOpenModal={setResearchStatusModal}
            classPost="height80Modal"
            ignoreAllowRefresh={true}
          >
            <div style={{ padding: "10px" }}>
              <Result
                select={true}
                onSelect={(research) => {
                  onSelectResearch(research);
                }}
                ignoreHeader={true}
              />
            </div>
          </ModalContainerNew>
        </div>
      </div>
    </FileSelect>
  );
};

export default ChatSendComponent;
