import React, { useState, useEffect } from "react";

const ConsultationCountdown = ({ date, callback = false }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  let intervalId = false;
  const calcEndTime = () => {
    const currentTime = new Date().getTime();
    const futureTime = new Date(date).getTime();
    const distance = futureTime - currentTime;

    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let timeLeftObj =
      distance >= 0
        ? {
            hours: ("0" + hours).slice(-2),
            minutes: ("0" + minutes).slice(-2),
            seconds: ("0" + seconds).slice(-2),
          }
        : false;

    setTimeLeft(timeLeftObj);
  };
  useEffect(() => {
    calcEndTime();

    const intervalId = setInterval(() => {
      calcEndTime();
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (timeLeft === false) {
      clearInterval(intervalId);
      if (typeof callback === "function") {
        callback();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <div style={{ width: "260px" }}>
      {timeLeft
        ? "До начала: " +
          (timeLeft.hours > 0 ? timeLeft.hours + ":" : "") +
          timeLeft.minutes +
          ":" +
          timeLeft.seconds
        : "Консультация началась"}
    </div>
  );
};

export default ConsultationCountdown;
