import { useEffect, useState } from "react";
import {
  verifyPaymentAction,
  verifyPaymentLinkAction,
} from "../../../base/asyncActions/Payment";
import ModalContainerNew from "../../Modal/ModalContainerNew";

export const PayStatus = ({
  openModal,
  setOpenModal,
  onClose,
  paymentData,
}) => {
  const [paidStatus, setPaidStatus] = useState("pending");

  useEffect(() => {
    let interval = false;
    if (paymentData?.consultation_id && !paymentData?.is_paid) {
      interval = setInterval(() => {
        verifyPaymentAction(
          paymentData.consultation_id,
          (status_code) => {
            // success
            if (status_code !== "pending") {
              setPaidStatus(status_code);
              clearInterval(interval);
            }
          },
          (error) => {
            // error
            console.log("error payment query", error);
          }
        );
      }, 3000);
    }

    if (paymentData?.payment_link) {
      interval = setInterval(() => {
        verifyPaymentLinkAction(
          paymentData?.payment_link,
          (status_code) => {
            // success
            if (status_code !== "pending") {
              setPaidStatus(status_code);
              clearInterval(interval);
            }
          },
          (error) => {
            // error
            console.log("error payment query", error);
          }
        );
      }, 3000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [
    paymentData.consultation_id,
    paymentData.is_paid,
    paymentData.payment_link,
  ]);

  useEffect(() => {
    if (paymentData.is_paid) {
      setPaidStatus("succeeded");
    }
  }, [paymentData.is_paid]);

  return (
    <ModalContainerNew
      openModal={openModal}
      setOpenModal={(status) => {
        setOpenModal(status);
      }}
      classPost="smallModal"
      onClose={() => {
        onClose(paidStatus);
      }}
    >
      <div style={{ maxHeight: "40vh", textAlign: "center" }}>
        {paidStatus === "pending" ? (
          <>
            <h2 style={{ marginBottom: "20px" }}>Проверяем оплату</h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ margin: "auto", background: "none" }}
              width="200"
              height="200"
              display="block"
              preserveAspectRatio="xMidYMid"
              viewBox="0 0 100 100"
            >
              <path fill="#407bff" d="M10 50a40 40 0 0080 0 40 42 0 01-80 0">
                <animateTransform
                  attributeName="transform"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 50 51;360 50 51"
                ></animateTransform>
              </path>
            </svg>
          </>
        ) : paidStatus === "succeeded" ? (
          <>
            <h2 style={{ marginTop: "10px", marginBottom: "20px" }}>
              {paymentData.consultation_id
                ? "Консультация успешно оплачена"
                : "Успешная оплата"}
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 30 30"
            >
              <path
                fill="#09a109"
                d="M15 1.5C7.557 1.5 1.5 7.557 1.5 15S7.557 28.5 15 28.5 28.5 22.443 28.5 15 22.443 1.5 15 1.5zm0 25.8C8.217 27.3 2.7 21.783 2.7 15S8.217 2.7 15 2.7 27.3 8.217 27.3 15 21.783 27.3 15 27.3zm6.723-16.923a.601.601 0 010 .849l-8.4 8.4a.598.598 0 01-.846-.003l-4.2-4.2a.601.601 0 01.849-.849l3.774 3.777 7.977-7.977a.595.595 0 01.846.003z"
              ></path>
            </svg>
          </>
        ) : (
          <>
            <h2 style={{ marginTop: "10px", marginBottom: "20px" }}>
              Ошибка оплаты
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              fill="none"
              viewBox="0 0 100 100"
            >
              <path
                stroke="#E90000"
                strokeWidth="5"
                d="M49.48 89.583c22.148 0 40.103-17.955 40.103-40.104S71.628 9.375 49.48 9.375 9.375 27.33 9.375 49.479c0 22.15 17.955 40.104 40.104 40.104z"
              ></path>
              <path
                stroke="#E90000"
                strokeLinecap="round"
                strokeWidth="5"
                d="M37.999 61.265l24.307-24.307m0 25.044L37.999 37.695"
              ></path>
            </svg>
          </>
        )}
      </div>
    </ModalContainerNew>
  );
};
