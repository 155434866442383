import { matchRoutes, useLocation, useNavigate } from "react-router-dom";

export const getRoute = (location) => {
  const routes = [
    { path: "/" },
    { path: "main" },
    { path: "login" },
    { path: "register" },
    { path: "profile" },
    { path: "profile/view" },
    { path: "profile/edit" },
    { path: "profile/balance" },
    { path: "profile/research" },
    { path: "profile/medcard" },
    { path: "profile/utility" },
    { path: "profile/notifications" },
    { path: "medical-direction" },
    { path: "medical-direction-offline" },
    { path: "doctors/:code/:spec_code" },
    { path: "doctors/:code" },
    { path: "doctor/:id/:type" },
    { path: "doctor/review/:id" },
    { path: "payment/:id" },
    { path: "my-consultation" },
    { path: "my-doctor" },
    { path: "consultation/:id" },
    { path: "consultation/:id/result" },
    { path: "docs/:type" },
    { path: "pay/:id" },
  ];
  try {
    const [{ route }] = matchRoutes(routes, location);
    return route.path;
  } catch (error) {
    return false;
  }
};

export const useHistoryUp = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return () => {
    let curentPath = getRoute(location);
    if (curentPath === "consultation/:id/result") {
      navigate("/my-consultation");
    } else {
      navigate(-1);
    }
    // switch (curentPath) {
    //   case 'profile/edit':
    //   case 'profile/balance':
    //   case 'profile/research':
    //   case 'profile/medcard':
    //   case 'profile/utility':
    //   case 'profile/notifications':
    //     upUrl = 'profile/view';
    //     break;

    //   case 'doctor/review/:id':
    //     let matchIdReview = location.pathname.match(/doctor\/review\/(\d+)$/);
    //     if(matchIdReview && matchIdReview[1]) {
    //       upUrl = `doctor/${matchIdReview[1]}/info`;
    //     }
    //     break;

    //   case 'payment/:id':
    //     let matchIdPayment = location.pathname.match(/payment\/(\d+)$/);
    //     if(matchIdPayment && matchIdPayment[1]) {
    //       upUrl = `doctor/${matchIdPayment[1]}/info`;
    //     }
    //     break;

    //   default:
    //     upUrl = 'main';
    //     break;
    // }

    // if(upUrl !== false) {
    //   navigate(upUrl);
    // }
  };
};
