import React, { useEffect } from "react";
import s from "./Utility.module.css";
import UtilityBlock from "../../../../Components/UtilityBlock/UtilityBlock";
import { getConfigHeaderAction } from "../../../../base/Reducers/configReducer";
import "./Sound.css";
import { useDispatch, useSelector } from "react-redux";

const Utility = () => {
  let dispatch = useDispatch();
  const config = useSelector((state) => state.config.config);

  useEffect(() => {
    let titleText = "Проверка оборудования";
    dispatch(getConfigHeaderAction(titleText));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <div className={s.Utility_full}>
      <div className={s.Utility_title}>
        <h1 className="title_config">Проверка оборудования</h1>
      </div>
      <UtilityBlock />
    </div>
  );
};
export default Utility;
