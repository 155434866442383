import { createEvent, createStore } from "effector";

export const setAppEvent = createEvent();

const initialApp = {
  deviceId: false,
  showPushModal: false,
};

export const $app = createStore(initialApp).on(setAppEvent, (state, params) => {
  return { ...state, ...params };
});

export const $deviceId = $app.map((item) => item.deviceId);
export const $showPushModal = $app.map((item) => item.showPushModal);
