import React, { Component } from "react";
import "./VideoRoomComponent.css";
import { OpenVidu } from "openvidu-browser";
import StreamComponent from "./stream/StreamComponent";
import ChatComponent from "./chat/ChatComponent";
import UserModel from "../models/user-model";
import ToolbarComponent from "./toolbar/ToolbarComponent";
import Countdown from "./countdown/Countdown";
import CountdowBefore from "./countdown/CountdowBefore";
import message from "../../../../../img/chat_message.png";
import dayJS from "dayjs";
import axios from "axios";
import { defaultUrl } from "../../../../../base/configUrl";

var localUser = new UserModel();

class VideoRoomComponent extends Component {
  constructor(props) {
    super(props);
    let consultation = this.props.consultation
      ? this.props.consultation
      : false;
    let callStatus = this.props.callStatus ? this.props.callStatus : false;
    let myUserName = consultation.user
      ? this.getShotrName(consultation.user)
      : "";
    let myUserPhoto =
      consultation.user && consultation.user.photo
        ? consultation.user.photo
        : "";
    let startTime =
      consultation && consultation.datetime ? consultation.datetime : false;
    let endTime =
      startTime && consultation.duration
        ? dayJS(startTime).add(consultation.duration, "minute").toISOString()
        : false;
    this.remotes = [];
    this.localUserAccessAllowed = false;
    this.state = {
      canStart: false,
      initStart: false,
      connectError: false,
      mediaPermission: false,
      consultation: consultation,
      myUserName: myUserName,
      myUserPhoto: myUserPhoto,
      startTime: startTime,
      endTime: endTime,
      session: undefined,
      localUser: undefined,
      subscribers: [],
      currentVideoDevice: undefined,
      showToolbar: true,
      showToolbarTimer: null,
      token: false,
      callStatus: callStatus,
    };

    localStorage.setItem("messageList", "");

    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
    this.camStatusChanged = this.camStatusChanged.bind(this);
    this.micStatusChanged = this.micStatusChanged.bind(this);
    this.switchCamera = this.switchCamera.bind(this);
    this.screenShare = this.screenShare.bind(this);
    this.stopScreenShare = this.stopScreenShare.bind(this);
    this.setCanStart = this.setCanStart.bind(this);
    this.startSession = this.startSession.bind(this);
  }

  getShotrName = (user) => {
    return `${user.lastname} ${user.firstname[0] + "."} ${
      user.secondname[0] + "."
    }`;
  };

  handleVideoClick = () => {
    return true;
    // this.setState(prevState => ({ showToolbar: !prevState.showToolbar }));
    // if (this.state.showToolbarTimer) {
    //     clearTimeout(this.state.showToolbarTimer);
    // }
    // const showToolbarTimer = setTimeout(() => this.setState({ showToolbar: false }), 5000);
    // this.setState({ showToolbarTimer });
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.onbeforeunload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onbeforeunload);
    this.leaveSession();
  }

  onbeforeunload(event) {
    this.leaveSession();
  }

  joinSession() {
    this.OV = new OpenVidu();
    this.OV.enableProdMode();

    this.setState(
      {
        session: this.OV.initSession(),
      },
      () => {
        this.subscribeToStreamCreated();
        this.connectToSession();
      }
    );
  }

  connectToSession() {
    if (this.state.token !== false) {
      console.log("token received: ", this.state.token);
      this.connect(this.state.token);
    } else {
      console.log("There was an error getting the token");
      alert("There was an error getting the token");
    }
  }

  connect(token) {
    this.state.session
      .connect(token, { clientData: this.state.myUserName })
      .then(() => {
        this.connectWebCam();
      })
      .catch((error) => {
        if (this.props.error) {
          this.props.error({
            error: error.error,
            messgae: error.message,
            code: error.code,
            status: error.status,
          });
        }
        alert("There was an error connecting to the session:", error.message);
        console.log(
          "There was an error connecting to the session:",
          error.code,
          error.message
        );
      });
  }

  async connectWebCam() {
    var devices = await this.OV.getDevices();
    var videoDevices = devices.filter((device) => device.kind === "videoinput");

    let publisher = this.OV.initPublisher(undefined, {
      audioSource: undefined,
      videoSource:
        videoDevices[0] && videoDevices[0].deviceId
          ? videoDevices[0].deviceId
          : false,
      publishAudio: localUser.isAudioActive(),
      publishVideo:
        videoDevices[0] && videoDevices[0].deviceId
          ? localUser.isVideoActive()
          : false,
      resolution: "640x480",
      frameRate: 30,
      insertMode: "APPEND",
    });

    if (this.state.session.capabilities.publish) {
      publisher.on("accessAllowed", () => {
        this.state.session.publish(publisher).then(() => {
          this.updateSubscribers();
          this.localUserAccessAllowed = true;
          if (this.props.joinSession) {
            this.props.joinSession();
          }
        });
      });
    }
    localUser.setNickname(this.state.myUserName);
    localUser.setPhoto(this.state.myUserPhoto);
    localUser.setConnectionId(this.state.session.connection.connectionId);
    localUser.setScreenShareActive(false);
    localUser.setStreamManager(publisher);
    this.subscribeToUserChanged();
    this.subscribeToStreamDestroyed();
    this.sendSignalUserChanged({
      isScreenShareActive: localUser.isScreenShareActive(),
    });

    this.setState(
      { currentVideoDevice: videoDevices[0], localUser: localUser },
      () => {
        this.state.localUser.getStreamManager().on("streamPlaying", (e) => {
          console.log("stream is playing");
        });
      }
    );
  }

  updateSubscribers() {
    var subscribers = this.remotes;
    this.setState(
      {
        subscribers: subscribers,
      },
      () => {
        if (this.state.localUser) {
          this.sendSignalUserChanged({
            isAudioActive: this.state.localUser.isAudioActive(),
            isVideoActive: this.state.localUser.isVideoActive(),
            nickname: this.state.localUser.getNickname(),
            isScreenShareActive: this.state.localUser.isScreenShareActive(),
          });
        }
      }
    );
  }

  leaveSession() {
    const mySession = this.state.session;

    if (mySession) {
      mySession.disconnect();
    }

    // Empty all properties...
    this.OV = null;
    this.setState({
      session: undefined,
      subscribers: [],
      localUser: undefined,
      canStart: false,
      initStart: false,
      connectError: false,
      mediaPermission: false,
      token: false,
    });

    window.localStreamVideo?.getTracks().forEach((track) => {
      track.stop();
    });
    window.localStreamAudio?.getTracks().forEach((track) => {
      track.stop();
    });

    if (typeof this.state.callStatus === "function") {
      this.state.callStatus(false);
    }
  }
  camStatusChanged() {
    localUser.setVideoActive(!localUser.isVideoActive());
    localUser.getStreamManager().publishVideo(localUser.isVideoActive());
    this.sendSignalUserChanged({ isVideoActive: localUser.isVideoActive() });
    this.setState({ localUser: localUser });
  }

  micStatusChanged() {
    localUser.setAudioActive(!localUser.isAudioActive());
    localUser.getStreamManager().publishAudio(localUser.isAudioActive());
    this.sendSignalUserChanged({ isAudioActive: localUser.isAudioActive() });
    this.setState({ localUser: localUser });
  }

  deleteSubscriber(stream) {
    const remoteUsers = this.state.subscribers;
    const userStream = remoteUsers.filter(
      (user) => user.getStreamManager().stream === stream
    )[0];
    let index = remoteUsers.indexOf(userStream, 0);
    if (index > -1) {
      remoteUsers.splice(index, 1);
      this.setState({
        subscribers: remoteUsers,
      });
    }
  }

  subscribeToStreamCreated() {
    this.state.session.on("streamCreated", (event) => {
      const subscriber = this.state.session.subscribe(event.stream, undefined);
      // var subscribers = this.state.subscribers;
      subscriber.on("streamPlaying", (e) => {
        this.checkSomeoneShareScreen();
      });
      const newUser = new UserModel();
      newUser.setStreamManager(subscriber);
      newUser.setConnectionId(event.stream.connection.connectionId);
      newUser.setType("remote");
      const nickname = event.stream.connection.data.split("%")[0];
      newUser.setNickname(JSON.parse(nickname).clientData);
      this.remotes.push(newUser);
      if (this.localUserAccessAllowed) {
        this.updateSubscribers();
      }
    });
  }

  subscribeToStreamDestroyed() {
    // On every Stream destroyed...
    this.state.session.on("streamDestroyed", (event) => {
      // Remove the stream from 'subscribers' array
      this.deleteSubscriber(event.stream);
      setTimeout(() => {
        this.checkSomeoneShareScreen();
      }, 20);
      event.preventDefault();
    });
  }

  subscribeToUserChanged() {
    this.state.session.on("signal:userChanged", (event) => {
      let remoteUsers = this.state.subscribers;
      remoteUsers.forEach((user) => {
        if (user.getConnectionId() === event.from.connectionId) {
          const data = JSON.parse(event.data);
          if (data.isAudioActive !== undefined) {
            user.setAudioActive(data.isAudioActive);
          }
          if (data.isVideoActive !== undefined) {
            user.setVideoActive(data.isVideoActive);
          }
          if (data.nickname !== undefined) {
            user.setNickname(data.nickname);
          }
          if (data.isScreenShareActive !== undefined) {
            user.setScreenShareActive(data.isScreenShareActive);
          }
        }
      });
      this.setState(
        {
          subscribers: remoteUsers,
        },
        () => this.checkSomeoneShareScreen()
      );
    });
  }

  sendSignalUserChanged(data) {
    const signalOptions = {
      data: JSON.stringify(data),
      type: "userChanged",
    };
    this.state.session.signal(signalOptions);
  }

  async switchCamera() {
    try {
      const devices = await this.OV.getDevices();
      var videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoDevices && videoDevices.length > 1) {
        var newVideoDevice = videoDevices.filter(
          (device) => device.deviceId !== this.state.currentVideoDevice.deviceId
        );

        if (newVideoDevice.length > 0) {
          // Creating a new publisher with specific videoSource
          // In mobile devices the default and first camera is the front one
          var newPublisher = this.OV.initPublisher(undefined, {
            audioSource: undefined,
            videoSource: newVideoDevice[0].deviceId,
            publishAudio: localUser.isAudioActive(),
            publishVideo: localUser.isVideoActive(),
            mirror: true,
          });

          //newPublisher.once("accessAllowed", () => {
          await this.state.session.unpublish(
            this.state.localUser.getStreamManager()
          );
          await this.state.session.publish(newPublisher);
          this.state.localUser.setStreamManager(newPublisher);
          this.setState({
            currentVideoDevice: newVideoDevice,
            localUser: localUser,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  screenShare() {
    const videoSource =
      navigator.userAgent.indexOf("Firefox") !== -1 ? "window" : "screen";
    const publisher = this.OV.initPublisher(
      undefined,
      {
        videoSource: videoSource,
        publishAudio: localUser.isAudioActive(),
        publishVideo: localUser.isVideoActive(),
        mirror: false,
      },
      (error) => {
        if (error && error.name === "SCREEN_EXTENSION_NOT_INSTALLED") {
          this.setState({ showExtensionDialog: true });
        } else if (error && error.name === "SCREEN_SHARING_NOT_SUPPORTED") {
          alert("Your browser does not support screen sharing");
        } else if (error && error.name === "SCREEN_EXTENSION_DISABLED") {
          alert("You need to enable screen sharing extension");
        } else if (error && error.name === "SCREEN_CAPTURE_DENIED") {
          alert("You need to choose a window or application to share");
        }
      }
    );

    publisher.once("accessAllowed", () => {
      this.state.session.unpublish(localUser.getStreamManager());
      localUser.setStreamManager(publisher);
      this.state.session.publish(localUser.getStreamManager()).then(() => {
        localUser.setScreenShareActive(true);
        this.setState({ localUser: localUser }, () => {
          this.sendSignalUserChanged({
            isScreenShareActive: localUser.isScreenShareActive(),
          });
        });
      });
    });
    publisher.on("streamPlaying", () => {
      console.log("stream playing!");
    });
  }

  stopScreenShare() {
    this.state.session.unpublish(localUser.getStreamManager());
    this.connectWebCam();
  }

  checkSomeoneShareScreen() {
    let isScreenShared;
    // return true if at least one passes the test
    isScreenShared =
      this.state.subscribers.some((user) => user.isScreenShareActive()) ||
      localUser.isScreenShareActive();
    console.log("screen shared?", isScreenShared);
  }

  setCanStart(value = true) {
    this.setState({
      canStart: value,
    });
  }

  startSession() {
    this.setState({
      initStart: true,
    });
    this.getPermissionDevices();
  }

  getPermissionDevices() {
    try {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) => {
          window.localStreamAudio = stream;
          navigator.mediaDevices
            .getUserMedia({
              video: true,
            })
            .then((stream) => {
              window.localStreamVideo = stream;
              this.setState({
                mediaPermission: true,
              });
              this.getSession();
            })
            .catch((error) => {
              this.setState({
                mediaPermission: true,
              });
              this.getSession();
            });
        })
        .catch((error) => {
          this.setState({
            mediaPermission: false,
          });
        });
    } catch (error) {
      console.log("getPermissionDevices", error);
    }
  }

  getSession() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    axios
      .get(
        `${defaultUrl}consultation/${this.state.consultation.consultation_id}/connect`
      )
      .then((response) => {
        if (response.data.status && response.data.data.room.token) {
          this.setState({
            token: response.data.data.room.token,
          });
          if (typeof this.state.callStatus === "function") {
            this.state.callStatus(true);
          }
          this.joinSession();
        } else {
          if (response.data.error.message) {
            this.setState({
              connectError: response.data.error.message,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const localUser = this.state.localUser;
    let localUserPosition = this.state.showToolbar ? "localUserUp" : "";
    let startMissingPermission =
      this.state.initStart && !this.state.mediaPermission ? true : false;
    let consultationIsEnd =
      dayJS(this.state.endTime).diff(dayJS(new Date()), "second") <= 0
        ? true
        : false;
    let is_ended = this.state.consultation.is_ended;
    let sub = this.state.subscribers.length
      ? this.state.subscribers[this.state.subscribers.length - 1]
      : false;
    return (
      <div className="VideoBlock">
        <div className="VideoBlockImage black_config">
          {!this.state.token ? (
            // before get token
            !this.state.canStart ? (
              <div className="TimerAbsolute white_config">
                <CountdowBefore
                  date={this.state.startTime}
                  onTimeEnd={this.setCanStart}
                />
              </div>
            ) : (
              <>
                {/* before press start consultation */}
                {!this.state.initStart && !consultationIsEnd && !is_ended ? (
                  // show button start consultation
                  !consultationIsEnd ? (
                    <button
                      onClick={this.startSession}
                      className={"AbsuluteCenter ButtonStart"}
                    >
                      Подключиться
                    </button>
                  ) : (
                    <div className="AbsuluteCenter white_config">
                      Консультация окончена
                    </div>
                  )
                ) : // show loader after click start consultation
                this.state.connectError === false && !consultationIsEnd ? (
                  <div
                    className="AbsuluteCenter"
                    style={{ marginTop: "-40px" }}
                  >
                    <svg
                      className="loaderRotate"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.8 6.2C28.4 8.6 30 12.2 30 16C30 23.8 23.8 30 16 30C8.2 30 2 23.8 2 16C2 12.2 3.6 8.6 6.2 6.2L4.6 4.6C1.8 7.6 0 11.6 0 16C0 24.8 7.2 32 16 32C24.8 32 32 24.8 32 16C32 11.6 30.2 7.6 27.4 4.6L25.8 6.2Z"
                        fill="#444444"
                      />
                    </svg>
                  </div>
                ) : (
                  <></>
                )}
                {/* message after user click start consultation and before get media permission */}
                {startMissingPermission ? (
                  <div className="AbsuluteCenter white_config">
                    Предоставьте доступ к камере и микрофону
                  </div>
                ) : (
                  ""
                )}
                {/* error message */}
                {this.state.connectError !== false ? (
                  <div className="AbsuluteCenter white_config">
                    {this.state.connectError}
                  </div>
                ) : (
                  ""
                )}
                {(consultationIsEnd || is_ended) && !this.state.connectError ? (
                  <div className="AbsuluteCenter white_config">
                    Консультация окончена
                  </div>
                ) : (
                  ""
                )}
              </>
            )
          ) : (
            // after get token (STARTED CONSULTATION)
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {sub ? (
                <div
                  onClick={this.handleVideoClick}
                  className="ImageVideo1 remoteUsers"
                >
                  <StreamComponent
                    user={sub}
                    streamId={sub.streamManager.stream.streamId}
                  />
                </div>
              ) : (
                <div
                  style={{ width: "100%", height: "100%" }}
                  onClick={this.handleVideoClick}
                ></div>
              )}
              {localUser !== undefined &&
                localUser.getStreamManager() !== undefined && (
                  <div
                    onClick={this.handleVideoClick}
                    className={"ImageVideo localUser " + localUserPosition}
                  >
                    <StreamComponent user={localUser} />
                  </div>
                )}
              {this.state.endTime || !is_ended ? (
                <div
                  className="TimerAbsolute white_config"
                  onClick={this.handleVideoClick}
                >
                  <Countdown
                    date={this.state.endTime}
                    onTimeEnd={this.leaveSession}
                  />
                </div>
              ) : (
                ""
              )}
              <ToolbarComponent
                showToolbar={this.state.showToolbar}
                user={this.state.localUser}
                consultation={this.state.consultation}
                camStatusChanged={this.camStatusChanged}
                micStatusChanged={this.micStatusChanged}
                screenShare={this.screenShare}
                stopScreenShare={this.stopScreenShare}
                switchCamera={this.switchCamera}
                leaveSession={this.leaveSession}
              />
            </div>
          )}
        </div>
        {localUser !== undefined &&
        localUser.getStreamManager() !== undefined ? (
          <ChatComponent
            user={localUser}
            consultation={this.state.consultation}
          />
        ) : (
          <div
            className={
              "VideoChat" + (this.props.mobile ? " mobileVideoChat" : "")
            }
          >
            <div className="message__content">
              <div className={"message__doctor"}>
                <div className={"message_block__doctor"}>
                  {is_ended ? (
                    <p className={"message__doctor_text black_config"}>
                      Консультация окончена.
                    </p>
                  ) : !consultationIsEnd ? (
                    <p className={"message__doctor_text black_config"}>
                      Нажмите «Подключиться», чтобы начать или продолжить
                      консультацию.
                    </p>
                  ) : (
                    <p className={"message__doctor_text black_config"}>
                      Консультация окончена. В скором времени вы получите
                      заключение от врача.
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="MessageText">
              <div className="MessageImg">
                <input
                  type="text"
                  placeholder="Введите текст сообщения..."
                  disabled
                />
                <img src={message} alt="" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default VideoRoomComponent;
