import React from "react";
import s from "../../Pages/Views/Consultation/Consultation.module.css";
import { useSelector } from "react-redux/es/exports";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import {
  axiosConsultationHistory,
  axiosConsultationDelete,
} from "../../base/asyncActions/getConsultation";
import Skeleton from "react-loading-skeleton";
const ConsultationHistory = () => {
  const page = useSelector((state) => state.consultation.page);
  const date_from = useSelector((state) => state.consultation.date_from);
  const date_to = useSelector((state) => state.consultation.date_to);
  let [statusDoc, setStatus] = useState(null);
  const totalPage = useSelector((state) => state.consultation.totalPage);
  let specialization_id = useSelector(
    (state) => state.consultation.specialization_id
  );
  let consultationHistory = useSelector(
    (state) => state.consultation.consultationHistory
  );
  let dispatch = useDispatch();
  useEffect(() => {
    async function fetchMyAPI() {
      let statusDoctor = await dispatch(axiosConsultationHistory());
      setStatus(statusDoctor.status);
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let DoctorDelete = (consultation_id) => {
    dispatch(axiosConsultationDelete(consultation_id));
  };

  let sceletonMap = [];
  for (var i = 0; i < 2; i++) {
    sceletonMap.push(i);
  }
  let loader = sceletonMap.map((sceletionId) => {
    return (
      <div className={s.Doctor_full + " black_config"} key={sceletionId}>
        <div className={s.Doctor_full1 + " " + s.Download_Doctor}>
          <div className={s.Doctor}>
            <div className={s.Doctor_infos}>
              <div className={s.Doctor_avatar}>
                <div className={s.Doctor_avatar_img}>
                  <Skeleton circle="1" style={{ height: "100%" }} />
                </div>
              </div>
            </div>
            <div className={s.Doctor_info + " " + s.black}>
              <p className={s.gray + " gray_config"}>
                <Skeleton style={{ width: "70%" }} />
              </p>
              <h2 className={s.Font_size24}>
                <Skeleton />
              </h2>
              <p className={s.Staj}>
                <Skeleton style={{ width: "60%" }} />
              </p>
              <div className={s.Doctor_buy}>
                <p className={s.gray + " gray_config"}>
                  <Skeleton style={{ width: "40%" }} />
                </p>
                <p className={s.buy}>
                  <Skeleton style={{ width: "20%" }} />
                </p>
              </div>
              <div className={s.Consultation_info_text}>
                <p className={s.gray + " gray_config"}>
                  <Skeleton style={{ width: "40%" }} />
                </p>
                <p className={s.buy}>
                  <Skeleton style={{ width: "35%" }} />
                </p>
              </div>
            </div>
          </div>
          <div className={s.Consultation_info}>
            <Skeleton
              className={s.Download_file}
              style={{ borderRadius: "24px" }}
            />
          </div>
        </div>
      </div>
    );
  });

  let history = consultationHistory.map((el, key) => (
    <div className={s.Doctor_full + " black_config"} key={key}>
      {el.can_cancel ? (
        <div
          className={s.Cart_close}
          onClick={() => {
            DoctorDelete(el.consultation_id);
          }}
        >
          +
        </div>
      ) : (
        ""
      )}

      <div className={s.Doctor_full1 + " " + s.Download_Doctor}>
        <div className={s.Doctor}>
          <div className={s.Doctor_infos}>
            <div className={s.Doctor_avatar}>
              <div className={s.Doctor_avatar_img}>
                <img alt="" src={el.doctor.photo} />
                {el.doctor.is_online && (
                  <div className={s.DoctorOnline + " green_config"}></div>
                )}
              </div>
              {/* <div className={s.Doctor_avatar_info}>
                <Stars num={el.doctor.rate} />
              </div> */}
            </div>
          </div>
          <div className={s.Doctor_info + " " + s.black}>
            <p className={s.gray + " gray_config"}>
              {el.doctor.specialization.join(" • ")}
            </p>
            <h2 className={s.Font_size24}>
              {el.doctor.lastname +
                " " +
                el.doctor.firstname +
                " " +
                el.doctor.secondname}
            </h2>
            <p className={s.Staj}>{el.doctor.regalia.join(" • ")}</p>
            <div className={s.Doctor_buy}>
              <p className={s.gray + " gray_config"}>Стоимость консультации:</p>
              <p className={s.buy}>{el.price} ₽</p>
            </div>
            <div className={s.Consultation_info_text}>
              <p className={s.gray + " gray_config"}>
                Консультация состоялась:
              </p>
              <p className={s.buy}>
                {new Date(el.datetime).toLocaleString("ru", {
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  year: "numeric",
                })}
              </p>
            </div>
          </div>
        </div>
        <div className={s.Consultation_info}>
          <Link to={"/consultation/" + el.consultation_id + "/result"}>
            <div className={s.Download_file + " opacityBlue"}>
              <div className={s.Download_img}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="56"
                  height="56"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 22.589 20.552"
                >
                  {" "}
                  <g data-name="Layer 2">
                    {" "}
                    <g data-name="Layer 1">
                      {" "}
                      <g data-name="medical records">
                        {" "}
                        <path
                          fill="#407bff"
                          d="M0 11.897l3.658-2.814v2.814zm22.589 0l-3.653-2.814v2.814z"
                          data-original="#6176cd"
                        ></path>{" "}
                        <path
                          fill="#134ed1"
                          d="M6.75 0h8.828v2.469H6.75z"
                          data-original="#4663b5"
                        ></path>{" "}
                        <path
                          fill="#407bff"
                          d="M4.988 2.469V6.36l12.353 2.793V2.469z"
                          data-original="#618ef4"
                        ></path>{" "}
                        <path
                          fill="#d9e1fe"
                          d="M3.353 4.948v6.949h15.883V7.589l-2.647-2.641z"
                          data-original="#d9e1fe"
                        ></path>{" "}
                        <path
                          fill="#f6ae2d"
                          d="M22.589 11.9H0v7.254a1.41 1.41 0 001.412 1.4h19.764a1.411 1.411 0 001.413-1.4z"
                          data-original="#faba62"
                        ></path>{" "}
                        <path
                          fill="#407bff"
                          d="M14.121 16.224a2.825 2.825 0 01-5.649 0 2.825 2.825 0 015.649 0z"
                          data-original="#5b77e0"
                        ></path>{" "}
                        <path
                          fill="#fff"
                          d="M12.973 15.696h-1.148v-1.148h-1.061v1.148H9.621v1.057h1.143V17.9h1.061v-1.147h1.148z"
                          data-original="#ffffff"
                        ></path>{" "}
                        <path
                          fill="#b1c2fe"
                          d="M16.589 7.589h2.647l-2.647-2.641z"
                          data-original="#b1c2fe"
                        ></path>{" "}
                        <path
                          fill="#134ed1"
                          d="M5.998 7.152h7.585v.884H5.998zm0 2.164h10.591v.874H5.998z"
                          data-original="#4663b5"
                        ></path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>{" "}
                </svg>
              </div>
              <div className={s.Download_text + " blue_config"}>
                <p className={s.Font_size14}>
                  {el.have_result
                    ? "Открыть заключение врача"
                    : "Заключение еще не готово"}
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  ));
  return (
    <div>
      <div className={s.History}>
        <div className={s.History_title + " title_config"}>
          <h1 className={s.Font_size36 + " title_config"}>История</h1>
        </div>
        {/* <div className={s.History_select + " black_config"}>
          <div className={s.History_special}>
            <p className={s.Font_size16 + " gray_config"}>Специализация</p>
            <SelectConsultation
              array={branch}
              selectType={"history"}
              func={consultationHistoryCons}
            />
          </div>
          <div className={s.History_date}>
            <p className={s.Font_size16 + " gray_config"}>Дата приёма</p>
            <SelectCalendar />
          </div>
        </div> */}
      </div>
      {statusDoc === null
        ? loader
        : consultationHistory.length > 0
        ? history
        : "У вас пока что не было консультаций"}
      {statusDoc && page < totalPage ? (
        <div
          className={s.Show_more + " " + s.Message_button_margin}
          onClick={(e) => {
            if (page < totalPage)
              dispatch(
                axiosConsultationHistory(
                  specialization_id,
                  page + 1,
                  date_to,
                  date_from
                )
              );
          }}
        >
          <Button
            className={s.Reviews_send + " " + s.Font_size14}
            type={"submit"}
            class={"btn white"}
            text={"Показать ещё"}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default ConsultationHistory;
