import React, { useState, useEffect, useRef } from "react";
import "./ChatComponent.css";
import ChatSendComponent from "./ChatSendComponent";
import ResultItem from "../../../../My_Profile/Result/ResultItem";
import FileItem from "../../../../My_Profile/Result/FileItem";

const ChatComponent = ({ user, mobile, customScrollBottom, consultation }) => {
  const [messageList, setMessageList] = useState([]);
  const chatScroll = useRef(null);

  useEffect(() => {
    if (mobile && localStorage.getItem("messageList")) {
      try {
        setMessageList(JSON.parse(localStorage.getItem("messageList")));
      } catch (error) {}
    }

    const handleChatSignal = (event) => {
      const data = JSON.parse(event.data);
      let messageUpdate = false;
      switch (data.type) {
        case "message":
          messageUpdate = {
            type: "message",
            connectionId: event.from.connectionId,
            nickname: data.nickname,
            message: data.message,
            time: data.time,
            photo: data.photo ? data.photo : "",
          };
          break;
        case "research":
          messageUpdate = {
            type: "research",
            connectionId: event.from.connectionId,
            nickname: data.nickname,
            research: data.research,
            time: data.time,
            photo: data.photo ? data.photo : "",
          };
          break;
        case "file":
          messageUpdate = {
            type: "file",
            connectionId: event.from.connectionId,
            nickname: data.nickname,
            file: data.file,
            time: data.time,
            photo: data.photo ? data.photo : "",
          };
          break;

        default:
          break;
      }

      if (!messageUpdate) {
        return false;
      }

      setMessageList((prevMessageList) => [...prevMessageList, messageUpdate]);

      localStorage.setItem("messageList", JSON.stringify(messageList));
      if (customScrollBottom) {
        customScrollBottom();
      } else {
        scrollToBottom();
      }
    };

    user?.getStreamManager().stream.session.on("signal:chat", handleChatSignal);

    return () => {
      user
        ?.getStreamManager()
        .stream.session.off("signal:chat", handleChatSignal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, mobile]);

  const scrollToBottom = () => {
    setTimeout(() => {
      try {
        chatScroll.current.scrollTop = chatScroll.current.scrollHeight;
      } catch (err) {}
    }, 20);
  };

  let lastSenderId = false;

  const messageRender = (data, fromSide) => {
    let message = "";
    switch (data.type) {
      case "message":
        const time = new Date(data.time);
        message = (
          <>
            <p className="gray_config">{time.toLocaleTimeString()}</p>
            <p
              className={
                (fromSide ? "message__doctor_text" : "message__my_text") +
                " black_config"
              }
            >
              {data.message}
            </p>
          </>
        );
        break;

      case "research":
        message = <ResultItem el={data.research} chatView={true} />;
        break;

      case "file":
        message = <FileItem el={data.file} consultation={consultation} />;
        break;

      default:
        break;
    }
    return message;
  };

  return (
    <div className={"VideoChat" + (mobile ? " mobileVideoChat" : "")}>
      <div className="message__content" ref={chatScroll}>
        {messageList.map((data, i) => {
          const fromSide = data.connectionId !== user.getConnectionId();
          const senderElem =
            lastSenderId !== data.connectionId ? (
              <div className={fromSide ? "from__doctor" : "from__my"}>
                <div className="message_sender">{data.nickname}</div>
                <div className="message_image">
                  <img src={data.photo} alt="" />
                </div>
              </div>
            ) : (
              ""
            );
          const messageElem = (
            <div
              key={i}
              className={fromSide ? "message__doctor" : "message__my"}
            >
              {senderElem}
              <div
                className={
                  fromSide ? "message_block__doctor" : "message_block__my"
                }
              >
                {messageRender(data, fromSide)}
              </div>
            </div>
          );
          lastSenderId = data.connectionId;
          return messageElem;
        })}
      </div>
      {!mobile ? (
        <ChatSendComponent user={user} consultation={consultation} />
      ) : null}
    </div>
  );
};

export default ChatComponent;
