import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { yooKassaJS } from "../../../base/configUrl";
import { PayStatus } from "./PayStatus";

const PayYookassaModal = ({ paymentData, onClose, onPaidClose }) => {
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const [yooKassaScriptLoaded, setYooKassaScriptLoaded] = useState(false);
  const [yooKassaCheckout, setYooKassaCheckout] = useState(false);

  const yooKassaPaymentFormId = "yookassa-payment-form";

  const addYooKassaScript = () => {
    const yooKassaElemtScriptId = "yookassa-widget-js";
    if (!document.getElementById(yooKassaElemtScriptId)) {
      const script = document.createElement("script");
      script.id = yooKassaElemtScriptId;
      script.src = yooKassaJS;
      script.addEventListener("load", () => {
        setYooKassaScriptLoaded(true);
      });
      document.getElementsByTagName("head")[0].appendChild(script);
    } else {
      setYooKassaScriptLoaded(true);
    }
  };

  // on load
  useEffect(() => {
    addYooKassaScript();
  }, []);

  const openPaymentService = () => {
    if (paymentData.token && yooKassaScriptLoaded) {
      const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: paymentData.token,
        customization: {
          modal: true,
        },
        error_callback: function (error) {
          setYooKassaCheckout(false);
          console.log(error);
        },
      });
      checkout.on("success", (event) => {
        if (checkout) {
          checkout.destroy();
          setYooKassaCheckout(false);
          setPaymentStatusModal(true);
        }
      });
      checkout.on("modal_close", () => {
        setYooKassaCheckout(false);
      });
      checkout.render(yooKassaPaymentFormId);
      setYooKassaCheckout(checkout);
    }
  };

  useEffect(() => {
    openPaymentService();
    // eslint-disable-next-line
  }, [yooKassaScriptLoaded, paymentData.token]);

  useEffect(() => {
    // TODO, SHIT HISTORY BUTTON BLOCK
    // NEED MAKE NORMAL
    const disableBackButton = (event) => {
      if (yooKassaCheckout) {
        event.preventDefault();
        yooKassaCheckout.destroy();
        setYooKassaCheckout(false);
      }
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", disableBackButton);

    return () => {
      window.removeEventListener("popstate", disableBackButton);
    };
  }, [yooKassaCheckout]);

  // eslint-disable-next-line
  const listenerPaymentModal = () => {
    console.log("message modal OPEN");
    openPaymentService();
  };

  useEffect(() => {
    window.addEventListener("openPaymentModal", listenerPaymentModal);
    return () => {
      window.removeEventListener("openPaymentModal", listenerPaymentModal);
    };
  }, [listenerPaymentModal]);

  const onClosePaidModal = (paidStatus) => {
    if (onClose) {
      onClose();
    }
    if (paidStatus === "succeeded") {
      setPaymentStatusModal(false);
      if (!paymentData.payment_link) {
        window.location.href = "/my-consultation";
      } else {
        if (onPaidClose) {
          onPaidClose(paidStatus);
        }
      }
    }
  };

  return (
    <>
      <div id={yooKassaPaymentFormId}></div>
      {paymentStatusModal ? (
        <PayStatus
          openModal={paymentStatusModal}
          setOpenModal={setPaymentStatusModal}
          paymentData={paymentData}
          onClose={(paidStatus) => {
            onClosePaidModal(paidStatus);
          }}
        />
      ) : null}
    </>
  );
};
export default PayYookassaModal;
