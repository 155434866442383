import React from "react";
import s from "./Balance.module.css";
import { useEffect, useState } from "react";
import Button from "../../../../Components/Button/Button";
import {
  axiosProfileBalance,
  axiosProfileRefferal,
  axiosProfileHistory,
} from "../../../../base/asyncActions/Profile";
import { useDispatch, useSelector } from "react-redux";
import { getConfigHeaderAction } from "../../../../base/Reducers/configReducer";
import Skeleton from "react-loading-skeleton";
const Balance = () => {
  // const [isShown, setIsShown] = useState(false);
  let [statusDoc, setStatus] = useState(false);
  let dispatch = useDispatch();
  let keyNum = 0;
  const balance = useSelector((state) => state.profile.balance);
  // const referral = useSelector((state) => state.profile.referral);
  let totalPage = useSelector((state) => state.profile.total_page);
  let page = useSelector((state) => state.profile.current_page);
  const history = useSelector((state) => state.profile.history);
  // let [errorType, seterrorType] = useState({
  //   status: false,
  //   error: {
  //     fields: {
  //       summ: [],
  //       friend: [],
  //     },
  //   },
  // });
  // let [errorMessage, seterrorMessage] = useState({
  //   status: false,
  //   error: {
  //     message: "",
  //   },
  // });
  const config = useSelector((state) => state.config.config);
  useEffect(() => {
    let titleText = "Баланс";
    async function fetchMyAPI() {
      dispatch(axiosProfileBalance());
      dispatch(axiosProfileRefferal());
      dispatch(getConfigHeaderAction(titleText));
      let statusDoctor = await dispatch(axiosProfileHistory(1));
      setStatus(statusDoctor.status);
    }
    fetchMyAPI();
    // let title = config?.config.title ? config?.config.title : "Telemed";
    // document.title = titleText + ' - ' + title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);
  // const CopyText = async (e) => {
  //   var copyText = document.querySelector(".refferal");
  //   const code = copyText.innerText;
  //   await navigator.clipboard.writeText(code);

  //   /* Copy the text inside the text field */
  //   document.execCommand("copy");
  // };
  const ShowClick = () => {
    if (totalPage > page++) {
      dispatch(axiosProfileHistory(page++));
    }
  };
  // const sendForm = async (e) => {
  //   e.preventDefault();
  //   const data = await new FormData(e.target);
  //   let obj = {};
  //   let response;
  //   [...data].forEach((e) => {
  //     obj[e[0]] = e[1];
  //   });
  //   obj.summ = Number(obj.summ);
  //   obj.friend = Number(obj.friend?.replace(/[\D]+/g, ""));
  //   !isShown
  //     ? (response = await dispatch(axiosProfilePay(obj)))
  //     : (response = await dispatch(axiosProfilePay(obj)));

  //   if (response.status && response.payment_url) {
  //     window.location.href = response.payment_url;
  //   }
  //   if (!response.status) {
  //     seterrorType((errorType) => ({
  //       error: {
  //         fields: {
  //           summ: response.error?.fields.summ
  //             ? [...response.error?.fields.summ]
  //             : [],
  //           friend: response.error?.fields.friend
  //             ? [...response.error?.fields.friend]
  //             : [],
  //         },
  //       },
  //     }));
  //     seterrorMessage((errorMessage) => ({
  //       error: {
  //         message: response.error?.message,
  //       },
  //     }));
  //   }
  // };
  let History = history.map((el) => (
    <div key={++keyNum} className="black_config">
      <div className={s.History_data}>
        <p>
          {new Date(el.datetime).toLocaleString("ru", {
            month: "short",
            year: "numeric",
            day: "numeric",
          })}
        </p>
      </div>{" "}
      <div className={s.History_content}>
        <p className={s.Font_size14}>{el.description}</p>
        <div className={s.History_content_text}>
          <b className={s.Font_size24}>{el.amount}₽</b>
        </div>
      </div>
    </div>
  ));

  let sceletonMap = [];
  for (var i = 0; i < 2; i++) {
    sceletonMap.push(i);
  }
  let loader = sceletonMap.map((sceletionId) => {
    return (
      <div key={sceletionId} className="black_config">
        <div className={s.History_data}>
          <p>
            <Skeleton style={{ width: "40%" }} />
          </p>
        </div>{" "}
        <div className={s.History_content}>
          <p className={s.Font_size14}>
            <Skeleton style={{ width: "20%" }} />
          </p>
          <div className={s.History_content_text}>
            <b className={s.Font_size24}>
              <Skeleton style={{ width: "100%" }} />
            </b>
            <b className={s.Font_size24}>
              <Skeleton style={{ width: "70%" }} />
            </b>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={s.Balance}>
      <div
        className={s.Balance_title + " title_config"}
        style={{ marginBottom: "14px" }}
      >
        <h1>Баланс: {balance}₽</h1>
      </div>
      {/* <form onSubmit={(e) => sendForm(e)}>
        <div className={s.Balance_add}>
          <Input
            required
            minLength={"2"}
            placeholder={"Сумма"}
            pattern={"^[0-9\\s]+$"}
            type={"number"}
            name={"summ"}
          />
          <Button
            className={s.Font_size14}
            type={"submit"}
            class={"btn blue"}
            text={"пополнить"}
          />
        </div>

        <FormErrors error={errorType.error.fields.summ} />
        <FormErrors error={errorMessage.error.message} />

        <div className={s.Balance_friend + " black_config"}>
          <input
            type="checkbox"
            id="Register_checkbox"
            className={s.custom_checkbox}
            onChange={(e) => setIsShown((current) => !current)}
          />

          <label htmlFor="Register_checkbox"></label>
          <p>Пополнить другу</p>
        </div>
        <div className={s.Input_friend}>
          {isShown ? (
            <InpMask
              pattern={"[+][7]\\s[(][0-9]{3}[)]\\s[0-9]{3}-[0-9]{2}-[0-9]{2}"}
              required
              placeholder={"Номер телефона друга"}
              type={"tel"}
              name={"friend"}
              className={"input"}
            />
          ) : (
            ""
          )}
          <FormErrors error={errorType.error.fields.friend} />
        </div>
      </form>
      <div className={s.Remove_balance}>
        <ModalContainer typeModalCont="RequestMoney" />
      </div> */}
      {/* <div className={s.Referal}>
        <div className={s.Referal_title + " title_config"}>
          <h1>Реферальный код</h1>
          <InfoModal
            text="" //TODO add text
            classtwo="infoFuncpop"
            class="infoFunc"
          />
        </div>
        <div className={s.Refferal_input}>
          <p className={s.balanceRefferal + " gray_config refferal"}>
            {referral}
          </p>
          <img alt="" className={s.Referal_img} src={copy} onClick={CopyText} />
        </div>
      </div> */}
      <div className={s.History}>
        <div className={s.History_title + " title_config"}>
          <h1>История</h1>
        </div>
        <div className={s.History_content_full}>
          {statusDoc ? History : loader}
          {page < totalPage ? (
            <div className={s.Message_button_margin} onClick={ShowClick}>
              <Button
                className={s.Show_more + " " + s.Font_size14}
                type={"submit"}
                class={"btn white"}
                text={"Показать ещё"}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default Balance;
