import React, { useState, useEffect, useRef } from "react";
import dayJS from "dayjs";

const CountdowBefore = ({ date, onTimeEnd }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const intervalIdRef = useRef(false);

  const calcEndTime = () => {
    const currentTime = new Date().getTime();
    const futureTime = new Date(date).getTime();
    const distance = futureTime - currentTime;

    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    let timeLeftObj =
      distance >= 0
        ? {
            hours: ("0" + hours).slice(-2),
            minutes: ("0" + minutes).slice(-2),
            seconds: ("0" + seconds).slice(-2),
          }
        : false;

    setTimeLeft(timeLeftObj);
  };

  const numberDeclension = (number, wordForms) => {
    const decimalNumber = Math.abs(number) % 100;
    const lastNumber = decimalNumber % 10;
    if (decimalNumber > 10 && decimalNumber < 20) {
      return wordForms[2];
    }
    if (lastNumber > 1 && lastNumber < 5) {
      return wordForms[1];
    }
    if (lastNumber === 1) {
      return wordForms[0];
    }
    return wordForms[2];
  };

  const formatTimeBefore = (datetime) => {
    const presentTime = dayJS(new Date());
    const consultationDate = dayJS(datetime);

    const weeksBefore = consultationDate.diff(presentTime, "week");
    const daysBefore = consultationDate.diff(presentTime, "day");
    const hoursBefore = consultationDate.diff(presentTime, "hour");
    const minutesBefore = consultationDate.diff(presentTime, "minute");

    const minuteWord = (number) =>
      numberDeclension(number, ["минута", "минуты", "минут"]);
    const hourWord = (number) =>
      numberDeclension(number, ["час", "часа", "часов"]);
    const dayWord = (number) =>
      numberDeclension(number, ["день", "дня", "дней"]);
    const weekWord = (number) =>
      numberDeclension(number, ["неделя", "недели", "недель"]);
    const restOfTheMinutes = minutesBefore % 60;
    const restOfTheHours = hoursBefore % 24;

    if (hoursBefore <= 0) {
      if (minutesBefore === 0) {
        return "меньше минуты";
      }
      return `${minutesBefore} ${minuteWord(minutesBefore)}`;
    }
    if (daysBefore <= 0) {
      return `${hoursBefore} ${hourWord(hoursBefore)} ${
        restOfTheMinutes
          ? `${restOfTheMinutes} ${minuteWord(restOfTheMinutes)}`
          : ""
      }`;
    }
    if (daysBefore < 3) {
      return `${daysBefore} ${dayWord(daysBefore)} ${
        restOfTheHours ? `${restOfTheHours} ${hourWord(restOfTheHours)}` : ""
      }`;
    }
    if (weeksBefore <= 0) {
      return `${daysBefore} ${dayWord(daysBefore)}`;
    }
    return `${weeksBefore} ${weekWord(weeksBefore)}`;
  };

  useEffect(() => {
    calcEndTime();

    intervalIdRef.current = setInterval(() => {
      calcEndTime();
    }, 1000);

    return () => clearInterval(intervalIdRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (timeLeft === false) {
      onTimeEnd();
      clearInterval(intervalIdRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <div>
      {timeLeft
        ? "До начала консультации осталось " + formatTimeBefore(date)
        : "Консультация началась!"}
    </div>
  );
};

export default CountdowBefore;
