import React from "react";
import s from "./NotFound.module.css";
import { Link } from "react-router-dom";
import Button from "../../../Components/Button/Button";

const NotFound = (props) => {
  let title = props.title ? props.title : 'Ошибка 404';
  let message = props.message ? props.message : 'Кажется тебя не должно быть тут';
  return <div className={s.notFound_container}>
    <div className={s.notFound_title}>{title}</div>
    <div className={s.notFound_description}>{message}</div>
    <Link className={s.notFound_action} to={"/main"}>
      <Button
        type={"submit"}
        class={"btn white"}
        text={"На главную?"}
      />
    </Link>
  </div>;
};
export default NotFound;
