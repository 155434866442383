import React from "react";
import s from "./My_Profile.module.css";
import { Outlet } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { useEffect } from "react";
import Chat from "../../../Components/Chat/Chat";
import { useSelector } from "react-redux";
const MyProfile = () => {
  const info = useSelector((state) => state.main.shortName);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={s.Container + " Container"}>
      <div className={s.My_Profile}>
        <div className={s.Left_Position}>
          <ul>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${s.navLink} ${s.headerActiveLink} blue_config`
                    : s.navLink + " gray_config"
                }
                to="view"
              >
                <p>Личные данные</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${s.navLink} ${s.headerActiveLink} blue_config`
                    : s.navLink + " gray_config"
                }
                to="notifications"
              >
                <div style={{ position: "relative" }}>
                  <p>Уведомления</p>
                  {info?.data?.has_notifications ? (
                    <div
                      style={{
                        position: "absolute",
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                        top: 0,
                        right: "-10px",
                      }}
                    ></div>
                  ) : null}
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${s.navLink} ${s.headerActiveLink} blue_config`
                    : s.navLink + " gray_config"
                }
                to="balance"
              >
                <p>Баланс</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${s.navLink} ${s.headerActiveLink} blue_config`
                    : s.navLink + " gray_config"
                }
                to="utility"
              >
                <p>Проверка оборудования</p>
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${s.navLink} ${s.headerActiveLink} blue_config`
                    : s.navLink + " gray_config"
                }
                to="medcard"
              >
                <p>Медицинская карта</p>
              </NavLink>
            </li> */}
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${s.navLink} ${s.headerActiveLink} blue_config`
                    : s.navLink + " gray_config"
                }
                to="research"
              >
                <p>Результаты исследований</p>
              </NavLink>
            </li>
          </ul>
          <div className={s.links + " blue_config"}>
            <Link to="/docs/agreement" className="blue_config">
              <p>Персональные данные</p>
            </Link>
            <Link to="/docs/policy" className="blue_config">
              <p>Политика конфиденциальности</p>
            </Link>
            <Link to="/docs/deal" className="blue_config">
              <p>Договор на оказание услуг</p>
            </Link>
          </div>
        </div>
        <Outlet />
      </div>
      <Chat />
    </div>
  );
};
export { MyProfile };
