import React from "react";
import { useSwiper } from "swiper/react";

const SliderNextButton = ({ children }) => {

const swiper = useSwiper();
    return (
        <div onClick={() => swiper.slideNext()}>
            {children}
        </div>
    )
}

export default SliderNextButton;