const defaultState = {
  consultation: [],
  consultationHistory: [],
  page: 1,
  totalPage: 1,
  specialization_id: 1,
  doctor: {},
  date_to: "",
  date_from: "",
  statusModal: false,
  spec_array: [],
};
const CONSULTATION_ARRAY = "CONSULTATION_ARRAY";
const HISTORY_ARRAY = "HISTORY_ARRAY";
const CONSULTATION_DELETE = "CONSULTATION_DELETE";
const CONSULTATION_CONNECT = "CONSULTATION_CONNECT";
const CONSULTATION_MODAL = "CONSULTATION_MODAL";
const HISTORYCONS_ARRAY = "HISTORYCONS_ARRAY";
function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}
export const consultationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CONSULTATION_ARRAY:
      return {
        ...state,
        consultation: [
          ...action.consultation_array.items.filter((el) => !el.is_upcomming),
        ],
      };
    case HISTORY_ARRAY:
      return {
        ...state,
        consultationHistory: getUniqueListBy(state.consultationHistory.concat(
          action.consultation_array.DoctorArray
        ), 'consultation_id'),
        page: action.consultation_array.page,
        totalPage: action.consultation_array.totalPage,
        specialization_id: action.consultation_array.specialization_id,
        date_from: action.consultation_array.date_from,
        date_to: action.consultation_array.date_to,
        spec_array: [
          { branch_id: 0, title: "Все" },
          ...action.consultation_array.spec_array,
        ],
      };
    case HISTORYCONS_ARRAY:
      return {
        ...state,
        consultationHistory: [...action.consultation_array.DoctorArray],
        page: action.consultation_array.page,
        totalPage: action.consultation_array.totalPage,
        specialization_id: action.consultation_array.specialization_id,
        date_from: action.consultation_array.date_from,
        date_to: action.consultation_array.date_to,
      };
    case CONSULTATION_DELETE:
      return {
        ...state,
        consultation: state.consultation.filter(
          (el) => action.user_id !== el.consultation_id
        ),
      };
    case CONSULTATION_CONNECT:
      return { ...state, connect: { ...action.connect } };
    case CONSULTATION_MODAL:
      return { ...state, statusModal: action.status };
    default:
      return state;
  }
};
export const consultationAction = (consultation_array) => ({
  type: CONSULTATION_ARRAY,
  consultation_array,
});
export const consultationHistoryAction = (consultation_array) => ({
  type: HISTORY_ARRAY,
  consultation_array,
});
export const consultationDeleteAction = (user_id) => ({
  type: CONSULTATION_DELETE,
  user_id,
});
export const consultationConnectAction = (connect) => ({
  type: CONSULTATION_CONNECT,
  connect,
});
export const consultationModalAction = (status) => ({
  type: CONSULTATION_MODAL,
  status,
});
export const consultationHistoryConsAction = (consultation_array) => ({
  type: HISTORYCONS_ARRAY,
  consultation_array,
});
