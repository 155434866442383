import axios from "axios";
import {
  getConfigAction,
  getAuthAction,
  getConfigPoliceAction,
} from "../Reducers/configReducer";
import { defaultUrl } from "../configUrl";
import { isMobile } from "react-device-detect";

export const axiosConfig = () => {
  const userAgent = navigator?.userAgent ?? false;
  const nativeAppInfo = userAgent
    ? userAgent.match(/(VseDocApp)\/(.+)/)
    : false;
  const isNativeApp =
    nativeAppInfo && nativeAppInfo[1] ? nativeAppInfo[1] : false;
  const nativeVersion =
    nativeAppInfo && nativeAppInfo[2] ? nativeAppInfo[2] : 0;
  let platform = isNativeApp ? "app" : isMobile ? "mobile" : "desktop";
  return async function (dispatch) {
    const response = await axios.get(
      `${defaultUrl}config?platform=${platform}&version=${nativeVersion}`
    );
    response.data.platform = platform;
    dispatch(getConfigAction(response.data));
  };
};

export const axiosAuth = (number, code) => {
  return async function (dispatch) {
    //стрелочная функция

    if (!code) {
      const response = await axios.get(`${defaultUrl}auth?phone=${number}`);
      dispatch(getAuthAction(number, response.data.status));
    } else {
      const response = await axios.get(
        `${defaultUrl}auth?phone=${number}&code=${code}`
      );
      if (response.data.status) {
        localStorage.setItem("token", response.data.token);
        dispatch(getAuthAction(number, false, response.data.is_new_user));
      }
    }
  };
};
export const getDocData = (type) => {
  return async function (dispatch) {
    const token = localStorage.getItem("token");
    if (token)
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await axios.get(`${defaultUrl}docs/${type}`);
    dispatch(getConfigPoliceAction(response.data.data));
  };
};
