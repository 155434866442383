import { useState } from "react";
import Button from "../Button/Button";
import axiosRequest from "../../base/axiosRequest";
import FormErrors from "../FormError/FormError";
import PayYookassaModal from "./modal/PayYookassaModal";
import PayPsbModal from "./modal/PayPsbModal";
import { PayStatus } from "./modal/PayStatus";

export function PayButton({
  color,
  consultation_uuid,
  doctor_id,
  slot_id,
  promocode,
  link_code,
  email,
  disabled = false,
  onSuccesPaid,
}) {
  const [isRequested, setIsRequested] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [paymentData, setPaymentData] = useState(false);
  const [paymentStatusModal, setPaymentStatusModal] = useState(true);

  const handleClick = async ({
    consultation_uuid,
    link_code,
    email,
    doctor_id,
    slot_id,
    promocode,
  }) => {
    if (isRequested) {
      return;
    }

    let result = false;
    setIsRequested(true);
    if (consultation_uuid) {
      result = await payConsultation({ consultation_uuid });
    } else if (link_code) {
      result = await payLink({ link_code, email });
    } else {
      result = await createConsultation({ doctor_id, slot_id, promocode });
    }
    setIsRequested(false);
    setPaymentData(validateResponse(result) ? result.data : false);
    if (result?.message) {
      setErrorMessage(result?.message);
    }
  };

  const payConsultation = async ({ consultation_uuid }) => {
    return await axiosRequest({
      method: "post",
      endpoint: `consultation/${consultation_uuid}/pay`,
    })
      .then((response) => {
        return response.data;
      })
      .catch((_error) => {
        setErrorMessage("Ошибка оплаты");
        return false;
      });
  };

  const payLink = async ({ link_code, email }) => {
    return await axiosRequest({
      method: "post",
      endpoint: `payment-link/${link_code}/pay`,
      data: {
        email,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((_error) => {
        setErrorMessage("Ошибка оплаты");
        return false;
      });
  };

  const createConsultation = async ({ doctor_id, slot_id, promocode }) => {
    return await axiosRequest({
      method: "post",
      endpoint: `consultation/create`,
      data: {
        doctor_id,
        slot_id,
        promocode,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((_error) => {
        setErrorMessage("Ошибка оплаты");
        return false;
      });
  };

  const validateResponse = (response) => {
    if (response && response.status) {
      switch (response.data?.type) {
        case "yookassa":
          if (!response.data?.token) {
            setErrorMessage("Ошибка оплаты");
            return false;
          }
          break;
        case "psb":
          if (!response.data?.url) {
            setErrorMessage("Ошибка оплаты");
            return false;
          }
          break;

        default:
          break;
      }
      return true;
    }
    return false;
  };

  const onClosePaidModal = (paidStatus) => {
    if (paidStatus === "succeeded") {
      setPaymentStatusModal(false);
      console.log("link_code", link_code);
      if (!link_code) {
        window.location.href = "/my-consultation";
      } else {
        if (onSuccesPaid) {
          onSuccesPaid();
        }
      }
    }
  };

  const getPaymentModal = (data) => {
    if (data?.is_paid) {
      return (
        <PayStatus
          openModal={paymentStatusModal}
          setOpenModal={setPaymentStatusModal}
          paymentData={paymentData}
          onClose={(paidStatus) => {
            onClosePaidModal(paidStatus);
          }}
        />
      );
    }
    if (!data?.type) {
      return null;
    }
    switch (data.type) {
      case "yookassa":
        return (
          <PayYookassaModal
            paymentData={data}
            onClose={() => {
              setPaymentData(false);
            }}
            onPaidClose={onClosePaidModal}
          />
        );
      case "psb":
        return (
          <PayPsbModal
            paymentData={data}
            onClose={() => {
              setPaymentData(false);
            }}
            onPaidClose={onClosePaidModal}
          />
        );

      default:
        break;
    }
    return null;
  };

  return (
    <>
      {paymentData ? getPaymentModal(paymentData) : null}
      {errorMessage ? <FormErrors error={errorMessage} /> : null}
      <Button
        type={"submit"}
        disabled={disabled}
        class={"full-width-mobile btn " + (color ? color : "blue")}
        text={"Оплатить"}
        loader={isRequested}
        onClick={() => {
          handleClick({
            consultation_uuid,
            link_code,
            email,
            doctor_id,
            slot_id,
            promocode,
          });
        }}
      />
    </>
  );
}
