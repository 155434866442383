import React from "react";
import s from "./ModalContainer.module.css";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import arrow from "../../img/arrow-left.png";
import settings from "../../img/settings.svg";
import Button from "../Button/Button";
import { consultationModalAction } from "../../base/Reducers/ConsultationReducer";
import ModalUtility from "./ModalUtility/ModalUtility";
import { ProfileUtilityAction } from "../../base/Reducers/UserReducer";
import RequestMoney from "./Request_money/RequestMoney";
import CancelRecord from "./Cancel_record/Cancel_Record";
import ModalCalendar from "./Modal_calendar/Modal_calendar";
import { BottomSheet } from "react-spring-bottom-sheet";
import ChangeLogin from "./Change_login/Change_login";
import "react-spring-bottom-sheet/dist/style.css";
import { nativeSendMessage } from "../../base/nativeController";
const ModalContainer = (props) => {
  let dispatch = useDispatch();
  let [showWindow, setWindow] = useState(false);
  const availableScreenWidth = window.screen.availWidth;
  useEffect(() => {
    if (!showWindow) {
      document.body.style.overflow = "auto";
      nativeSendMessage({
        action: "allowRefresh",
        data: { status: true, here: 5 },
      });
    }
    if (showWindow) {
      document.body.style.overflow = "hidden";
      nativeSendMessage({
        action: "allowRefresh",
        data: { status: false },
      });
    }
  }, [showWindow]);
  return (
    <div>
      {props.typeModalCont === "CancelRecord" ? (
        <div
          className={s.Cart_close + " " + s.black}
          onClick={(e) => setWindow(true)}
        >
          <svg
            style={{ position: "absolute" }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 23 23"
          >
            <path
              stroke="#8B9BAB"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21.043 1.83l-9.908 9.888m0 0l-9.906 9.887m9.906-9.887l9.908 9.887m-9.908-9.887L1.229 1.831"
            ></path>
          </svg>
        </div>
      ) : props.typeModalCont === "RequestMoney" ? (
        <div
          className={s.blue + " " + s.Request}
          onClick={(e) => setWindow(true)}
        >
          <img alt="" src={arrow} />
          <p className={"blue_config "}>Вывести средства</p>
        </div>
      ) : props.typeModalCont === "ChangeLogin" ? (
        // <p
        //   onClick={() => setWindow(true)}
        //   className={
        //     s.Profile_replace_tel_link + " " + s.Font_size14 + " blue_config"
        //   }
        // >
        //   Изменить
        // </p>
        <></>
      ) : props.typeModalCont === "ModalCalendar" ? (
        <div
          onClick={(e) => {
            setWindow(true);
            dispatch(consultationModalAction(false));
          }}
        >
          {props.type_of === "1" ? (
            <p className={s.link_blue + " " + s.Font_size14 + " blue_config"}>
              Изменить дату и время приёма
            </p>
          ) : (
            <Button type={"submit"} class={"btn orange"} text={"перенести"} />
          )}
        </div>
      ) : props.typeModalCont === "ModalUtility" ? (
        <div className={s.moduleIcon}>
          <div
            className={s.icon_back}
            onClick={(e) => {
              setWindow(true);
              dispatch(ProfileUtilityAction(true));
            }}
          >
            <img alt="" src={settings} />
          </div>
          <p>Настройки</p>
        </div>
      ) : (
        ""
      )}
      {showWindow ? (
        <>
          {availableScreenWidth <= 480 ? (
            <BottomSheet open={showWindow} onDismiss={() => setWindow(false)}>
              {props.typeModalCont === "CancelRecord" ? (
                <CancelRecord {...props} setWindow={setWindow} />
              ) : props.typeModalCont === "RequestMoney" ? (
                <RequestMoney {...props} setWindow={setWindow} />
              ) : props.typeModalCont === "ChangeLogin" ? (
                <ChangeLogin {...props} setWindow={setWindow} />
              ) : props.typeModalCont === "ModalCalendar" ? (
                <ModalCalendar {...props} setWindow={setWindow} />
              ) : (
                ""
              )}
            </BottomSheet>
          ) : (
            <div className={s.Cancel_Record_full}>
              <div
                className={s.background}
                onClick={() => {
                  setWindow(false);
                  dispatch(ProfileUtilityAction(false));
                }}
              ></div>
              <div className={s.Cancel_Record_block}>
                {props.typeModalCont === "CancelRecord" ? (
                  <CancelRecord {...props} setWindow={setWindow} />
                ) : props.typeModalCont === "RequestMoney" ? (
                  <RequestMoney {...props} setWindow={setWindow} />
                ) : props.typeModalCont === "ChangeLogin" ? (
                  <ChangeLogin {...props} setWindow={setWindow} />
                ) : props.typeModalCont === "ModalCalendar" ? (
                  <ModalCalendar {...props} setWindow={setWindow} />
                ) : props.typeModalCont === "ModalUtility" ? (
                  <ModalUtility {...props} setWindow={setWindow} />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default ModalContainer;
