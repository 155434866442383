import React from "react";
import s from "../../Pages/Views/Main/Main.module.css";
import Razdeli from "../../Pages/Views/Razdeli/Razdeli";

const SectionsMedicine = () => {
  return (
    <>
      <section className={s.medicine + " " + s.container + " title_config"}>
        <h2 className={s.medicine_title + " " + s.Font_size40}>
          Разделы медицины
        </h2>
        <div className={s.medicine_cards}>
          <Razdeli mainPage={true} />
        </div>
      </section>
    </>
  );
};

export default SectionsMedicine;
