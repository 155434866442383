import React, { useEffect } from "react";
import style from "./Login.module.css";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { LoginForm } from "../../../Components/Forms/LoginForm";

const Login = (props) => {
  const error = useSelector((state) => state.login.error);
  const log_in = localStorage.getItem("login");
  const config = useSelector((state) => state.config.config);

  useEffect(() => {
    let title = config?.config.title ? config?.config.title : "Telemed";
    document.title = 'Авторизация - ' + title;
  }, [config]);

  return (
    <div className={"Container"}>
      {log_in ? <Navigate to={"/register"} /> : false}
      <div className={style.login_container}>
        <div
          className={style.login_text}
          style={{ color: config?.config.colors.color2 }}
        >
          <img alt="" className={style.login_image} src={config?.config.logo} />
          <h1
            className={style.login_title}
            style={{ color: config?.config.colors.color5 }}
          >
            {config?.config.login_info.title}
          </h1>
          {config?.config.login_info.list ? 
            <ul className={style.login_list}>
              {config?.config.login_info.list.map((item, k) => {
                return <li key={k} className={style.login_item}>
                  {item}
                </li>
              })}
            </ul>
            : ''
          }
        </div>
        <div style={{ maxWidth: "400px", width: "100%", marginBottom: "30px" }}>
          <p
            className={style.login_form_text}
            style={{
              color: config?.config.colors.color4,
              borderBottom: `4px solid ${config?.config.colors.color10}`,
            }}
          >
            ВОЙТИ
          </p>
          {error ? (
            <p style={{ color: "red", marginBottom: "10px" }}>{error}</p>
          ) : (
            ""
          )}
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export { Login };
