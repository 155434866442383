import React, { useCallback } from "react";
import s from "./Video.module.css";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import ModalContainer from "../../../Components/Modal/ModalContainer";
// import VolumeModal from "../../../Components/Modal/Volume_modal/VolumeModal";
import { getConfigHeaderAction } from "../../../base/Reducers/configReducer";
import { ProfileAudioAction } from "../../../base/Reducers/UserReducer";
import "./VideoChat/index.css";
import VideoRoomComponent from "./VideoChat/components/VideoRoomComponent";
import Skeleton from "react-loading-skeleton";
import NotFound from "../NotFound/NotFound";
import axios from "axios";
import { defaultUrl } from "../../../base/configUrl";

const Video = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  let [showWindow, setWindow] = useState(false);
  let [consultation, setConsultation] = useState(null);
  const [callStatus, setCallStatus] = useState(null);
  const [isEnded, setIsEnded] = useState(false);

  useEffect(() => {
    dispatch(getConfigHeaderAction("Консультация"));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.localStreamAudio?.getTracks().forEach((track) => {
      track.stop();
    });
    window.localStreamVideo?.getTracks().forEach((track) => {
      track.stop();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let VolumeShow = useSelector((state) => state.profile.VolumeShow);
  useEffect(() => {
    setWindow(VolumeShow);
    // eslint-disable-next-line
  }, [VolumeShow]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    axios
      .get(`${defaultUrl}consultation/${id}`)
      .then((response) => {
        if (response.data.status && response.data.data) {
          if (typeof response.data.data.is_ended !== "undefined") {
            setIsEnded(response.data.data.is_ended);
          }
          setConsultation(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callStatusUpdate = (value) => {
    setCallStatus(value);
  };

  const getIsComplited = useCallback(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    axios
      .get(`${defaultUrl}consultation/${id}/complite`)
      .then((response) => {
        if (response.data.status) {
          navigate(`/consultation/${id}/result`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, navigate]);

  useEffect(() => {
    const updateTimer = setInterval(() => {
      getIsComplited();
    }, 5000);
    return () => {
      clearInterval(updateTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (callStatus === false) {
      getIsComplited();
    }
  }, [callStatus, getIsComplited]);

  useEffect(() => {
    getIsComplited();
  }, [isEnded, getIsComplited]);

  return consultation !== null ? (
    consultation.doctor ? (
      <div className={s.VideoFull + " Container"}>
        {showWindow ? (
          <div
            className={s.background}
            onClick={(e) => dispatch(ProfileAudioAction(false))}
          ></div>
        ) : (
          ""
        )}
        <div className={s.VideoTitle + " title_config"}>
          <h1>{`${consultation.doctor.lastname} ${consultation.doctor.firstname} ${consultation.doctor.secondname}`}</h1>
          <p className={s.Video_Doctor_spec}>
            {consultation.doctor.specialization.join(" • ")}
          </p>
          <p>{consultation.doctor.regalia.join(" • ")}</p>
        </div>
        <VideoRoomComponent
          consultation={consultation}
          callStatus={callStatusUpdate}
        />
      </div>
    ) : (
      <NotFound
        title="Ошибка"
        message="Консультация еще не началась или уже закончилась."
      />
    )
  ) : (
    <div className={s.VideoFull + " " + s.Container + " Container"}>
      <div className={s.VideoTitle + " title_config"}>
        <Skeleton style={{ width: "60%", height: "2em" }} />
        <Skeleton style={{ width: "40%" }} className={s.Video_Doctor_spec} />
        <Skeleton style={{ width: "30%" }} />
      </div>
      <div className={s.VideoBlock}>
        <Skeleton
          style={{ height: "100%" }}
          containerClassName={
            s.VideoBlockImage + " " + s.VideoBlockImageSkeleton
          }
        />
        <Skeleton style={{ height: "100%" }} containerClassName={s.VideoChat} />
      </div>
    </div>
  );
};
export default Video;
