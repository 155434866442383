import s from "../../Pages/Views/Doctor_list/Doctor_list.module.css";
import style from "../../Pages/Views/Main/Main.module.css";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getDoctorDuty } from "../../base/asyncActions/getMainPageInfo";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
const DoctorsOnDuty = () => {
  const [Doctor, setDoctor] = useState(null),
    dispatch = useDispatch(),
    getDoctor = async () => {
      setDoctor(false);
      const response = await dispatch(getDoctorDuty());
      if (response && response.status && response.data && response.data.items) {
        setDoctor(response.data.items);
      } else {
        setDoctor(null);
      }
    };
  useEffect(() => {
    getDoctor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let sceletonMap = [];
  for (var i = 0; i < 2; i++) {
    sceletonMap.push(i);
  }

  return Doctor !== null ? (
    <section className={style.SectionDuty}>
      <div className={style.Container_list}>
        <div className={style.Title_list + " title_config"}>
          <h1 className={style.Font_size40}>Дежурные врачи: онлайн прием</h1>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", columnGap: "30px" }}>
          {Doctor === false
            ? sceletonMap.map((sceletionId) => {
                return (
                  <div
                    className={s.Doctor_infos}
                    key={sceletionId}
                    style={{ maxWidth: "500px" }}
                  >
                    <div className={s.Doctor_avatar}>
                      <div className={s.Doctor_avatar_img}>
                        <Skeleton circle="1" style={{ height: "100%" }} />
                      </div>
                      <div
                        className={s.Doctor_avatar_info + " " + s.black}
                      ></div>
                    </div>
                    <div className={s.Doctor_info + " " + s.black}>
                      <div className=" gray_config">
                        <p className={s.Font_size14 + " gray_config"}>
                          <Skeleton style={{ width: "30%" }} />
                        </p>
                      </div>

                      <h2 className={s.Font_size24}>
                        <Skeleton count="2" style={{ width: "60%" }} />
                      </h2>
                      <p className={s.Staj + " " + s.Font_size14}>
                        <Skeleton style={{ width: "90%" }} />
                      </p>
                    </div>
                  </div>
                );
              })
            : Doctor.map((el) => (
                <Link
                  to={"../doctor/" + el.doctor_id + "/info"}
                  style={{
                    maxWidth: "500px",
                    cursor: "pointer",
                    textDecoration: "none",
                    margin: "0px",
                  }}
                  className={s.Doctor + " black_config"}
                  key={el.doctor_id}
                >
                  <div className={s.Doctor_infos}>
                    <div className={s.Doctor_avatar}>
                      <div className={s.Doctor_avatar_img}>
                        <img alt="" src={el.photo} />
                        {el.is_online && (
                          <div
                            className={s.DoctorOnline + " green_config"}
                          ></div>
                        )}
                      </div>
                      {/* <div className={s.Doctor_avatar_info + " " + s.black}>
                      <Stars num={el.rate} />
                    </div> */}
                    </div>
                    <div className={s.Doctor_info + " " + s.black}>
                      <div className=" gray_config">
                        <p className={s.Font_size14 + " gray_config"}>
                          {el.specialization.join(" • ")}
                        </p>
                      </div>

                      <h2 className={s.Font_size24}>
                        {el.lastname + " " + el.firstname + " " + el.secondname}
                      </h2>
                      <p className={s.Staj + " " + s.Font_size14}>
                        {el.regalia.join(" • ")}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default DoctorsOnDuty;
