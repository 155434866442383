import axios from "axios";
import { defaultUrl } from "../configUrl";

export const setUserDevice = async (deviceInfo) => {
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  const response = await axios.post(`${defaultUrl}user/device`, {
    deviceInfo,
  });
  return response.data;
};

export const setUserDeviceToken = async (deviceId, pushToken) => {
  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  const response = await axios.post(
    `${defaultUrl}user/device/${deviceId}/token`,
    {
      push_token: pushToken,
    }
  );
  return response.data;
};
