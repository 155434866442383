import React, { useState } from "react";
import s from "../Recording.module.css";
import AnswerReviews from "./answerReviews";
import { Link } from "react-router-dom";
import Button from "../../../../Components/Button/Button";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { axiosReview } from "../../../../base/asyncActions/getReviews";
import Skeleton from "react-loading-skeleton";
const Reviews = (props) => {
  let dispatch = useDispatch();
  const config = useSelector((state) => state.config.config);
  const setLoaded = () => {
    setReviewsLoaded(true);
  };
  useEffect(() => {
    if (!recording?.reviewsArray[0])
      dispatch(axiosReview(props.usId, 1, setLoaded));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let recording = useSelector((state) => state.recording);
  let keyNum = 0;
  let page = useSelector((state) => state.recording.current_page);
  let totalPage = useSelector((state) => state.recording.total_page);
  const [reviewsLoaded, setReviewsLoaded] = useState(false);
  let rateText = (num) => {
    num = Math.ceil(parseInt(num));
    let rateStr = ["Отвратительно", "Плохо", "Нормально", "Хорошо", "Отлично"];
    return rateStr[num];
  };
  let review = recording.reviewsArray.map((el) => (
    <div key={++keyNum} className={s.ReviewsFull}>
      <div className={s.Doctor_infos}>
        <div className={s.Doctor_avatar}>
          <div className={s.Doctor_avatar_img}>
            <img alt="" src={config.config.images.notify} />
          </div>
          {/* <div className={s.Doctor_avatar_info + " " + s.black}>
            <Stars num={el.rate} />
          </div> */}
          <div className={s.Font_size14 + " " + s.black}>
            {rateText(el.rate)}
          </div>
        </div>
        <div className={s.Doctor_info + " " + s.black}>
          <p className={s.gray + " " + s.Font_size14}>
            {new Date(el.datetime).toLocaleString("ru", {
              month: "short",
              year: "numeric",
              day: "numeric",
            })}
          </p>
          <h2 className={s.Font_size24 + " title_config"}>{el.fistname}</h2>
          <p
            className={
              s.Staj + " " + s.gray + " " + s.Font_size14 + " gray_config"
            }
          >
            Прошёл консультацию: {el.consultation_date}
          </p>
          <div className={s.Doctor_info_content}>
            <div className={s.Doctor_info_favorite}>
              <b>Понравилось</b>
              <p className={s.Font_size14}>{el.like}</p>
            </div>
            <div className={s.Doctor_info_unfavorite}>
              <b>Не понравилось</b>
              <p className={s.Font_size14}>{el.not_like}</p>
            </div>
            <div className={s.Doctor_info_message}>
              <b>Комментарий</b>
              <p className={s.Font_size14}>{`${el.comment}`}</p>
              <AnswerReviews arrayAnswer={el.answers} />
            </div>
          </div>
        </div>
      </div>
      <div className={s.LineEndComment}></div>
    </div>
  ));
  let sceletonMap = [];
  for (var i = 0; i < 2; i++) {
    sceletonMap.push(i);
  }
  let loader = sceletonMap.map((sceletionId) => {
    return (
      <div key={sceletionId} className={s.ReviewsFull}>
        <div className={s.Doctor_infos}>
          <div className={s.Doctor_avatar}>
            <div className={s.Doctor_avatar_img}>
              <Skeleton circle="1" style={{ height: "100%" }} />
            </div>
          </div>
          <div className={s.Doctor_info + " " + s.black}>
            <p className={s.gray + " " + s.Font_size14}>
              <Skeleton style={{ width: "20%" }} />
            </p>
            <h2 className={s.Font_size24 + " title_config"}>
              <Skeleton style={{ width: "30%" }} />
            </h2>
            <p
              className={
                s.Staj + " " + s.gray + " " + s.Font_size14 + " gray_config"
              }
            >
              <Skeleton style={{ width: "50%" }} />
            </p>
            <div className={s.Doctor_info_content}>
              <div className={s.Doctor_info_favorite}>
                <b>
                  <Skeleton style={{ width: "30%" }} />
                </b>
                <p className={s.Font_size14}>
                  <Skeleton style={{ width: "70%" }} />
                </p>
              </div>
              <div className={s.Doctor_info_unfavorite}>
                <b>
                  <Skeleton style={{ width: "35%" }} />
                </b>
                <p className={s.Font_size14}>
                  <Skeleton style={{ width: "70%" }} />
                </p>
              </div>
              <div className={s.Doctor_info_message}>
                <b>
                  <Skeleton style={{ width: "30%" }} />
                </b>
                <p className={s.Font_size14}>
                  <Skeleton style={{ width: "98%" }} />
                  <Skeleton style={{ width: "90%" }} />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={s.LineEndComment}></div>
      </div>
    );
  });
  return (
    <div>
      {reviewsLoaded > 0 ? review : loader}
      <div className={s.Reviews_buttons_full}>
        <div className={s.Reviews_buttons}>
          {page < totalPage ? (
            <div
              className={s.Message_button_margin}
              onClick={(e) => {
                if (page < totalPage) dispatch(axiosReview(props.usId, ++page));
              }}
            >
              <Button
                className={s.Show_more + " " + s.Font_size14}
                type={"submit"}
                class={"btn white"}
                text={"Показать ещё"}
              />
            </div>
          ) : (
            <></>
          )}
          <Link to={"../doctor/review/" + props.usId}>
            <Button
              className={s.Reviews_send + " " + s.Font_size14}
              type={"submit"}
              class={"btn orange"}
              text={"Оставить отзыв"}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Reviews;
