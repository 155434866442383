import axios from "axios";
import { defaultUrl } from "./configUrl";

const axiosRequest = async (props) => {
  const contentType = props.params
    ? { "Content-Type": "application/json" }
    : {};

  const tokenItem = localStorage.getItem("token");
  const token = tokenItem ? { Authorization: `Bearer ${tokenItem}` } : {};

  let options = {
    ...props,
    url: defaultUrl + props.endpoint,
    headers: { ...contentType, ...token, ...props.headers },
  };

  const response = await axios(options);
  return response;
};

export default axiosRequest;
