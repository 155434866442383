import React from "react";
import "./Button.css";
import { useState } from "react";
import { useSelector } from "react-redux";
const Button = (props) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const config = useSelector((state) => state.config.config);

  const styleBlue = {
    backgroundColor: !isHover
      ? config?.config.colors.color10
      : config?.config.colors.color12,
    color: config?.config.colors.color1,
  };
  const styleOrange = {
    backgroundColor: !isHover
      ? config?.config.colors.color7
      : config?.config.colors.color13,
    color: config?.config.colors.color2,
  };
  const styleWhite = {
    backgroundColor: !isHover
      ? config?.config.colors.color1
      : config?.config.colors.color11,
    color: config?.config.colors.color4,
    border: `1px solid ${config?.config.colors.color6}`,
  };
  return (
    <button
      type={props.type}
      className={props.class}
      onClick={props.onClick}
      disabled={props.disabled ? true : false}
      style={
        props.class === "btn blue"
          ? styleBlue
          : props.class === "btn orange"
          ? styleOrange
          : props.class === "btn white"
          ? styleWhite
          : {}
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!props?.loader ? (
        props.text
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "center",
            gap: "14px",
            alignItems: "center",
          }}
        >
          {props.text}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            display="block"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 100 100"
            style={{ background: "transparent" }}
          >
            <path fill="currentColor" d="M10 50a40 40 0 0080 0 40 42 0 01-80 0">
              <animateTransform
                attributeName="transform"
                dur="1s"
                keyTimes="0;1"
                repeatCount="indefinite"
                type="rotate"
                values="0 50 51;360 50 51"
              ></animateTransform>
            </path>
          </svg>
        </div>
      )}
    </button>
  );
};
export default Button;
