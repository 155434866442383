import React, { useEffect, useState } from "react";
import s from "./Consultation.module.css";
import ConsultationReady from "../../../Components/Consultation_ready/ConsultationReady";
import ConsultationNext from "../../../Components/Consultation_Components/ConsultationNext";
import ConsultationHistory from "../../../Components/Consultation_Components/ConsultationHistory";
import { getConfigHeaderAction } from "../../../base/Reducers/configReducer";
import { useDispatch } from "react-redux";
import Chat from "../../../Components/Chat/Chat";

const Consultation = () => {
  let dispatch = useDispatch();
  const [existUpcomming, setExistUpcomming] = useState(false);

  useEffect(() => {
    dispatch(getConfigHeaderAction("Мои записи"));
    window.scrollTo(0, 0);
  });

  const updateExistUpcomming = () => {
    setExistUpcomming(true);
  }

  return (
    <div className={s.Container + " Container"}>
      <div className={s.My_consultation}>
        <div className={s.Consultation_title}>
          <h1 className={s.Font_size36 + " title_config"}>
            Предстоящая консультация
          </h1>
        </div>
        <ConsultationReady upcommingCallback={updateExistUpcomming}/>
        <ConsultationNext existUpcomming={existUpcomming}/>
        <ConsultationHistory />
        <Chat />
      </div>
    </div>
  );
};

export default Consultation;
