import React, { Component } from 'react';
import './ToolbarComponent.css';
// import VolumeModal from "../../../../../../Components/Modal/Volume_modal/VolumeModal";
// import ModalContainer from "../../../../../../Components/Modal/ModalContainer";
import ModalUnityChat from "../../../../../../Components/Modal/UtilityModal_Chat/UtilityChat";
import micro_off from "../../../../../../img/mic_off.svg";
import micro_on from "../../../../../../img/mic_on.svg";
import videocam_off from "../../../../../../img/videocam_off.svg";
import videocam_on from "../../../../../../img/videocam_on.svg";
import close from "../../../../../../img/close.svg";

export default class ToolbarComponent extends Component {
    constructor(props) {
        super(props);
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.screenShare = this.screenShare.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.switchCamera = this.switchCamera.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
    }

    micStatusChanged() {
        this.props.micStatusChanged();
    }

    camStatusChanged() {
        this.props.camStatusChanged();
    }

    screenShare() {
        this.props.screenShare();
    }

    stopScreenShare() {
        this.props.stopScreenShare();
    }

    switchCamera() {
        this.props.switchCamera();
    }

    leaveSession() {
        this.props.leaveSession();
    }

    render() {
        const localUser = this.props.user;
        return (
            <div className="toolbar" style={!this.props.showToolbar ? {display: "none"} : {}}>
                <ul
                    className="videoDesktop black_config"
                    style={{
                        width: '340px',
                        height: 'auto', 
                        transform: 'translate(-50%, 0%)'
                    }}
                >
                    {/* <VolumeModal /> */}
                    <li style={{display:'flex'}} onClick={this.micStatusChanged}>
                        <div className="icon_back">
                            <img alt="" src={localUser !== undefined && !localUser.isAudioActive() ? micro_off : micro_on} />
                        </div>
                        <p>{localUser !== undefined && !localUser.isAudioActive() ? 'Включить микрофон' : 'Выключить микрофон'}</p>
                    </li>
                    <li style={{display:'flex'}} onClick={this.camStatusChanged}>
                        <div className="icon_back">
                            <img src={localUser !== undefined && !localUser.isVideoActive() ? videocam_off : videocam_on} alt="" />
                        </div>
                        <p>{localUser !== undefined && !localUser.isVideoActive() ? 'Включить камеру' : 'Выключить камеру'}</p>
                    </li>
                    <li style={{display:'flex'}} onClick={this.leaveSession}>
                        <div className="icon_back icon_close">
                            <img src={close} alt="" />
                        </div>
                        <p>Отключиться</p>
                    </li>
                    {/* <li>
                        <ModalContainer typeModalCont="ModalUtility" />
                    </li> */}
                </ul>
                <ul className="videoMobile">
                    <li onClick={this.micStatusChanged}>
                        <div className="icon_back">
                            <img src={localUser !== undefined && !localUser.isAudioActive() ? micro_off : micro_on} alt="" />
                        </div>
                        <p>{localUser !== undefined && !localUser.isAudioActive() ? 'Включить микрофон' : 'Выключить микрофон'}</p>
                    </li>
                    <li onClick={this.camStatusChanged}>
                        <div className="icon_back">
                            <img src={localUser !== undefined && !localUser.isVideoActive() ? videocam_off : videocam_on} alt="" />
                        </div>
                        <p>{localUser !== undefined && !localUser.isVideoActive() ? 'Включить камеру' : 'Выключить камеру'}</p>
                    </li>
                    <li onClick={this.leaveSession}>
                        <div className="icon_back icon_close">
                            <img src={close} alt="" />
                        </div>
                        <p>Отключиться</p>
                    </li>
                    <ModalUnityChat localUser={localUser} consultation={this.props.consultation}/>
                </ul>
            </div>
        );
    }
}
