import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import s from "./SliderApp.module.css";
import "swiper/css";
import "swiper/css/pagination";
import "../SliderDots/SliderDots.css";
import { Pagination } from "swiper";
import { useSelector } from "react-redux";
import Loader from "../../Loading/Loader";
import Button from "../../Button/Button";
import SliderNextButton from "./SliderNextButton";
import { Link, Navigate } from "react-router-dom";
const SliderApp = () => {
  const [Slider, setSlider] = useState([]),
    config = useSelector((state) => state.config.config),
    SliderInfo = async () => {
      setSlider(config?.module.welcome_screen.screen);
    };

  useEffect(() => {
    SliderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let title = config?.config.title ? config?.config.title : "Telemed";
    document.title = title;
  }, [config]);

  const log_in = localStorage.getItem("login");
  return (
    <section style={{ marginBottom: "70px" }}>
      {log_in ? (
        <Navigate to={"/login"} />
      ) : (
        <>
          {!Slider ? (
            <Loader />
          ) : (
            <Swiper pagination={true} modules={[Pagination]} initialSlide={0}>
              {Slider.map((el, key) => (
                <SwiperSlide
                  key={key}
                  className={s.mySwiper}
                  style={{
                    backgroundColor: el.background_color,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div className={s.SliderSlide}>
                    <div className={s.SliderVCenter}>
                      {el.logo ? (
                        <div className={s.SliderLogo}>
                          <img alt="" src={el.logo} />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className={s.SliderText}>
                        <h1 style={{ color: el.title_color }}>{el.title}</h1>
                        <p style={{ color: el.description_color }}>
                          {el.description}
                        </p>
                      </div>
                      <div className={s.SliderImage}>
                        <img alt="" src={el.image} />
                      </div>
                    </div>
                    <div className={s.Button_Reviws}>
                      {Slider.length - 1 === key ? (
                        <Link to="../login">
                          <Button
                            className={s.Reviews_send + " " + s.Font_size14}
                            type={"submit"}
                            class={"btn orange"}
                            text={"Начать"}
                          />
                        </Link>
                      ) : (
                        <SliderNextButton>
                          <Button
                            className={s.Reviews_send + " " + s.Font_size14}
                            type={"submit"}
                            class={"btn orange"}
                            text={"Далее"}
                          />
                        </SliderNextButton>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </>
      )}
    </section>
  );
};

export default SliderApp;
