import s from "../Modal/Change_login/Change_login.module.css";
const FormErrors = ({error}) => {
  return <p
    style={{
      color: "red",
      cursor: "default",
      marginTop: "10px",
      marginBottom: "10px",
    }}
    className={s.Font_size14}
  >
    {error}
  </p>
};

export default FormErrors;
