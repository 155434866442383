import React from "react";
import s from "./My_Doctor.module.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  axiosMyDoctor,
  axiosDoctorDelete,
} from "../../../base/asyncActions/getDoctors";
import { Link } from "react-router-dom";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import Button from "../../../Components/Button/Button";
import { getConfigHeaderAction } from "../../../base/Reducers/configReducer";
import Chat from "../../../Components/Chat/Chat";
import Skeleton from "react-loading-skeleton";
const MyDoctor = () => {
  const dispatch = useDispatch();
  let [statusDoc, setStatus] = useState(false);
  const page = useSelector((state) => state.doctorSpec.page);
  const totalPage = useSelector((state) => state.doctorSpec.totalPage);
  const Doctors = useSelector((state) => state.doctorSpec.DoctorMy_array);

  useEffect(() => {
    async function fetchMyAPI() {
      let statusDoctor = await dispatch(axiosMyDoctor(1, true));
      setStatus(statusDoctor.status);
      dispatch(getConfigHeaderAction("Мои врачи"));
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let showMore = () => {
    if (page + 1 <= totalPage) {
      dispatch(axiosMyDoctor(page + 1));
    }
  };
  let sceletonMap = [];
  for (var i = 0; i < 2; i++) {
    sceletonMap.push(i);
  }
  let loader = sceletonMap.map((sceletionId) => {
    return (
      <div className={s.Doctor + " black_config"} key={sceletionId}>
        <div className={s.Doctor_infos}>
          <div className={s.Doctor_avatar}>
            <div className={s.Doctor_avatar_img}>
              <Skeleton circle="1" style={{ height: "100%" }} />
            </div>
          </div>
          <div className={s.Doctor_info + " " + s.black}>
            <p className={s.Font_size14 + " gray_config"}>
              <Skeleton style={{ width: "70%" }} />
            </p>
            <h2 className={s.Font_size24}>
              <Skeleton style={{ width: "100%" }} />
            </h2>
            <p className={s.Staj + " " + s.Font_size14}>
              <Skeleton style={{ width: "60%" }} />
            </p>
            <Skeleton
              className={s.Record_desktop_sceleton}
              style={{
                width: "200px",
                marginTop: "25px",
                borderRadius: "16px",
                height: "60px",
              }}
            />
          </div>
        </div>
        <div className={s.Doctor_button}>
          <Skeleton
            className={s.Reviews_send}
            style={{ marginTop: "20px", height: "50px", borderRadius: "16px" }}
          />
        </div>
      </div>
    );
  });
  let Doctor =
    Doctors.length > 0 ? (
      Doctors.map((el, key) => (
        <div className={s.Doctor + " black_config"} key={key}>
          <ModalContainer
            typeModalCont="CancelRecord"
            type_modal="myDoctor"
            consultation_id={el.doctor_id}
            text={"Вы действительно хотите удалить врача?"}
            func={axiosDoctorDelete}
            typeModal={"record"}
          />
          <div className={s.Doctor_infos}>
            <div className={s.Doctor_avatar}>
              <div className={s.Doctor_avatar_img}>
                <img alt="" src={el.photo} />
                {el.is_online && (
                  <div className={s.DoctorOnline + " green_config"}></div>
                )}
              </div>
              {/* <div className={s.Doctor_avatar_info + " " + s.black}>
            <Stars num={el.rate} />
          </div> */}
            </div>
            <div className={s.Doctor_info + " " + s.black}>
              <p className={s.Font_size14 + " gray_config"}>
                {el.specialization.join(" • ")}
              </p>
              <h2 className={s.Font_size24}>
                {el.lastname + " " + el.firstname + " " + el.secondname}
              </h2>
              <p className={s.Staj + " " + s.Font_size14}>
                {el.regalia.join(" • ")}
              </p>
              <Link to={"../doctor/" + el.doctor_id + "/info"}>
                <Button
                  className={s.Reviews_send + " " + s.Font_size14}
                  type={"submit"}
                  class={"btn blue"}
                  text={"Записаться"}
                />
              </Link>
            </div>
          </div>
          <div className={s.Doctor_button}>
            <Link to={"../doctor/" + el.doctor_id + "/info"}>
              <Button
                className={s.Reviews_send + " " + s.Font_size14}
                type={"submit"}
                class={"btn blue"}
                text={"Записаться"}
              />
            </Link>
          </div>
        </div>
      ))
    ) : (
      <div className={s.Doctor + " black_config"}>
        <div style={{ width: "100%", textAlign: "center" }}>
          Здесь появятся врачи к которым вы записывались на консультации
        </div>
      </div>
    );
  return (
    <div className={s.Container + " Container"}>
      {statusDoc ? Doctor : loader}
      {statusDoc && page < totalPage ? (
        <div className={s.Reviews_buttons}>
          <div className={s.Reviews_buttons_mobile} onClick={showMore}>
            <Button
              className={s.Show_more + " " + s.Font_size14}
              type={"submit"}
              class={"btn white"}
              text={"Показать ещё"}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <Chat />
    </div>
  );
};
export { MyDoctor };
